"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  Badge: () => Badge_default,
  BarChart: () => BarChart_default,
  Box: () => Box_default,
  BreadCrumb: () => BreadCrumb_default,
  Button: () => Button_default,
  CheckBox: () => CheckBox_default,
  DataTable: () => DataTable_default,
  Drawer: () => Drawer,
  EChart: () => EChart_default,
  FileInput: () => FileInput_default,
  FormInputGroup: () => FormInputGroup_default,
  FormProvider: () => FormProvider,
  GaugeChart: () => GaugeChart_default,
  GoogleButton: () => GoogleButton_default,
  IconButton: () => IconButton_default,
  Label: () => Label_default,
  LabelChart: () => LabelChart_default,
  LineChart: () => LineChart_default,
  Logo: () => Logo_default,
  LogoLoader: () => LogoLoader_default,
  MicrosoftButton: () => MicrosoftButton_default,
  OverlayLoader: () => OverlayLoader,
  PageHeading: () => PageHeading,
  PieChart: () => PieChart_default,
  ProviderController: () => import_react_hook_form7.Controller,
  RHFCheckbox: () => RHFTextField,
  RHFNumericField: () => RHFNumericField,
  RHFSelectField: () => RHFSelectField,
  RHFTextArea: () => RHFTextArea,
  RHFTextField: () => RHFTextField2,
  RadioInput: () => RadioInput_default,
  ReactSelect: () => ReactSelect,
  RowLoader: () => RowLoader_default,
  SelectInput: () => SelectInput_default,
  Spinner: () => Spinner_default,
  TableBody: () => TableBody_default,
  TableBodyCell: () => TableBodyCell_default,
  TableFooter: () => TableFooter_default,
  TableHeadCell: () => TableHeadCell_default,
  TableHeader: () => TableHeader_default,
  TablePaginateCell: () => TablePaginateCell_default,
  TablePagination: () => TablePagination_default,
  TextArea: () => TextArea_default,
  TextInput: () => TextInput_default,
  Tooltip: () => Tooltip_default,
  Typography: () => Typography_default,
  useProviderFormContext: () => import_react_hook_form7.useFormContext
});
module.exports = __toCommonJS(src_exports);

// src/components/Button/GoogleButton.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var sizes = {
  sm: "px-3.5 py-2 text-sm",
  md: "px-4 py-2.5 text-sm",
  lg: "px-4 py-[10px] text-base",
  xl: "px-5 py-3 text-base",
  "2xl": "px-7 py-4 text-lg"
};
var GoogleButton = (_a) => {
  var _b = _a, {
    variant = "sm" /* SM */,
    text
  } = _b, extraProps = __objRest(_b, [
    "variant",
    "text"
  ]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(
    "button",
    __spreadProps(__spreadValues({
      type: extraProps.type || "button",
      className: `${sizes[variant]} bg-white border py-2 w-full
      rounded mt-5 flex justify-center items-center text-sm
      hover:scale-105 duration-300 text-[#002D74]`
    }, extraProps), {
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(
          "svg",
          {
            xmlns: "http://www.w3.org/2000/svg",
            width: "21",
            height: "21",
            viewBox: "0 0 21 21",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("title", { children: "MS-SymbolLockup" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", { x: "1", y: "1", width: "9", height: "9", fill: "#f25022" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", { x: "1", y: "11", width: "9", height: "9", fill: "#00a4ef" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", { x: "11", y: "1", width: "9", height: "9", fill: "#7fba00" }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", { x: "11", y: "11", width: "9", height: "9", fill: "#ffb900" })
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(
          "svg",
          {
            className: "mr-3",
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 48 48",
            width: "25px",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "path",
                {
                  fill: "#FFC107",
                  d: "M43.611,20.083H42V20H24v8h11.303c-1.649,\n          4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,\n          5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,\n          4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,\n          22.659,43.862,21.35,43.611,20.083z"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "path",
                {
                  fill: "#FF3D00",
                  d: "M6.306,14.691l6.571,4.819C14.655,\n          15.108,18.961,12,24,12c3.059,0,5.842,1.154,\n          7.961,3.039l5.657-5.657C34.046,6.053,29.268,\n          4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "path",
                {
                  fill: "#4CAF50",
                  d: "M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,\n          35.091,26.715,36,24,36c-5.202,0-9.619-3.3git p17-11.283-7.946l-6.522,\n          5.025C9.505,39.556,16.227,44,24,44z"
                }
              ),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
                "path",
                {
                  fill: "#1976D2",
                  d: "M43.611,20.083H42V20H24v8h11.303c-0.792,\n          2.237-2.231,4.166-4.087,5.571c0.001-0.001,\n          0.002-0.001,0.003-0.002l6.19,5.238C36.971,\n          39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                }
              )
            ]
          }
        ),
        text
      ]
    })
  );
};
var GoogleButton_default = GoogleButton;

// src/components/Button/MicrosoftButton.tsx
var import_jsx_runtime2 = require("react/jsx-runtime");
var sizes2 = {
  sm: "px-3.5 py-2 text-sm",
  md: "px-4 py-2.5 text-sm",
  lg: "px-4 py-[10px] text-base",
  xl: "px-5 py-3 text-base",
  "2xl": "px-7 py-4 text-lg"
};
var GoogleButton2 = (_a) => {
  var _b = _a, {
    variant = "sm" /* SM */,
    text
  } = _b, extraProps = __objRest(_b, [
    "variant",
    "text"
  ]);
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
    "button",
    __spreadProps(__spreadValues({
      type: extraProps.type || "button",
      className: `${sizes2[variant]} bg-white border py-3 w-full
      rounded mt-5 flex gap-x-2 justify-center items-center text-md font-semibold text-gray-700
      hover:bg-gray-50 duration-300`
    }, extraProps), {
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
          "svg",
          {
            xmlns: "http://www.w3.org/2000/svg",
            width: "21",
            height: "21",
            viewBox: "0 0 21 21",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("title", { children: "MS-SymbolLockup" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("rect", { x: "1", y: "1", width: "9", height: "9", fill: "#f25022" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("rect", { x: "1", y: "11", width: "9", height: "9", fill: "#00a4ef" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("rect", { x: "11", y: "1", width: "9", height: "9", fill: "#7fba00" }),
              /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("rect", { x: "11", y: "11", width: "9", height: "9", fill: "#ffb900" })
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)("span", { children: [
          " ",
          text
        ] })
      ]
    })
  );
};
var MicrosoftButton_default = GoogleButton2;

// src/components/Button/IconButton.tsx
var import_jsx_runtime3 = require("react/jsx-runtime");
var sizes3 = {
  sm: "px-1 py-1 text-xs md:px-3.5 md:py-2 md:text-sm",
  md: "px-1 py-1 text-sm",
  lg: "px-1 py-1 md:px-4 md:py-[10px] text-base",
  xl: "px-1 py-1 text-base",
  "2xl": "px-1 py-1 text-lg"
};
var colors = {
  primary: "bg-primary-800 hover:bg-primary-900 focus:bg-primary-900 text-white",
  error: "bg-error-600 hover:bg-error-700 focus:bg-error-700 text-white",
  warning: "bg-warning-600 hover:bg-warning-700 focus:bg-warning-700 text-white",
  success: "bg-success-600 hover:bg-success-700 focus:bg-success-700 text-white",
  outline_primary: "bg-transparent border border-primary-800 text-gray-700",
  outline_error: "bg-transparent border border-error-600 text-gray-700",
  outline_warning: "bg-transparent border border-warning-600 text-gray-700",
  outline_success: "bg-transparent border border-success-600 text-gray-700"
};
var disableColors = {
  primary: "bg-primary-600 text-white",
  error: "bg-error-600 text-white",
  warning: "bg-warning-600 text-white",
  success: "bg-success-400 text-white",
  outline_primary: "bg-transparent border border-primary-600 text-gray-700",
  outline_error: "bg-transparent border border-error-600 text-gray-700",
  outline_warning: "bg-transparent border border-warning-600 text-gray-700",
  outline_success: "bg-transparent border border-success-600 text-gray-700"
};
var IconButton = (_a) => {
  var _b = _a, {
    disabled,
    variant = "lg" /* LG */,
    text,
    color = "primary" /* PRIMARY */,
    children
  } = _b, extraProps = __objRest(_b, [
    "disabled",
    "variant",
    "text",
    "color",
    "children"
  ]);
  const classes = extraProps.className || "";
  delete extraProps.className;
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
    "button",
    __spreadProps(__spreadValues({
      disabled,
      type: extraProps.type || "button",
      className: `rounded ${sizes3[variant]} font-semibold leading-none focus:outline-none ${classes} ${disabled ? `${disableColors[color]} text-white` : colors[color]}`
    }, extraProps), {
      children: children || text
    })
  );
};
var IconButton_default = IconButton;

// src/components/Button/index.tsx
var import_jsx_runtime4 = require("react/jsx-runtime");
var sizes4 = {
  sm: "px-3 py-[7px] text-xs md:px-3.5 md:py-2 md:text-sm",
  md: "px-4 py-2.5 text-sm",
  lg: "px-3 py-[8px] md:px-4 md:py-[10px] text-base",
  xl: "px-5 py-3 text-base",
  "2xl": "px-7 py-4 text-lg"
};
var colors2 = {
  primary: "bg-primary-800 hover:bg-primary-900 focus:bg-primary-900 text-white",
  error: "bg-error-600 hover:bg-error-700 focus:bg-error-700 text-white",
  warning: "bg-warning-600 hover:bg-warning-700 focus:bg-warning-700 text-white",
  success: "bg-success-600 hover:bg-success-700 focus:bg-success-700 text-white",
  outline_primary: "bg-transparent border border-primary-800 text-gray-700",
  outline_error: "bg-transparent border border-error-600 text-gray-700",
  outline_warning: "bg-transparent border border-warning-600 text-gray-700",
  outline_success: "bg-transparent border border-success-600 text-gray-700"
};
var disableColors2 = {
  primary: "bg-primary-600 text-white",
  error: "bg-error-600 text-white",
  warning: "bg-warning-600 text-white",
  success: "bg-success-400 text-white",
  outline_primary: "bg-transparent border border-primary-600 text-gray-700",
  outline_error: "bg-transparent border border-error-600 text-gray-700",
  outline_warning: "bg-transparent border border-warning-600 text-gray-700",
  outline_success: "bg-transparent border border-success-600 text-gray-700"
};
var Button = (_a) => {
  var _b = _a, {
    disabled,
    variant = "lg" /* LG */,
    text,
    color = "primary" /* PRIMARY */,
    children
  } = _b, extraProps = __objRest(_b, [
    "disabled",
    "variant",
    "text",
    "color",
    "children"
  ]);
  const classes = extraProps.className || "";
  delete extraProps.className;
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
    "button",
    __spreadProps(__spreadValues({
      disabled,
      type: extraProps.type || "button",
      className: `rounded ${sizes4[variant]} font-semibold leading-none focus:outline-none ${classes} ${disabled ? `${disableColors2[color]} text-white` : colors2[color]}`
    }, extraProps), {
      children: children || text
    })
  );
};
var Button_default = Button;

// src/components/EChart/index.tsx
var import_react = require("react");
var echarts = __toESM(require("echarts"));
var import_jsx_runtime5 = require("react/jsx-runtime");
var EChart = ({
  height = "100%",
  width = "100%",
  opt,
  onClick,
  suppressLegendSelection
}) => {
  const chartContElmRef = (0, import_react.useRef)(null);
  const [eChart, setEChart] = (0, import_react.useState)();
  (0, import_react.useEffect)(() => {
    let chart;
    if (chartContElmRef.current !== null) {
      chart = echarts.init(chartContElmRef.current);
    }
    const resizeChart = () => chart == null ? void 0 : chart.resize();
    window.addEventListener("resize", resizeChart);
    setEChart(chart);
    return () => {
      chart == null ? void 0 : chart.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, []);
  (0, import_react.useEffect)(() => {
    eChart == null ? void 0 : eChart.resize();
  }, [eChart, height, width]);
  (0, import_react.useEffect)(() => {
    if (eChart && onClick) {
      eChart.on("click", (e) => onClick(eChart, e));
    }
  }, [eChart, onClick]);
  (0, import_react.useEffect)(() => {
    if (eChart && suppressLegendSelection) {
      eChart.on(
        "legendselectchanged",
        (e) => suppressLegendSelection(eChart, e)
      );
    }
  }, [eChart]);
  (0, import_react.useEffect)(() => {
    if (eChart) {
      eChart.setOption(opt);
    }
  }, [eChart, opt]);
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
    "div",
    {
      ref: chartContElmRef,
      style: { height, width },
      className: "graph-content"
    }
  );
};
var EChart_default = EChart;

// src/components/PieChart/index.tsx
var import_react2 = require("react");
var import_jsx_runtime6 = require("react/jsx-runtime");
var option = {
  tooltip: {
    trigger: "item",
    position: ["0%", "0%"]
  },
  legend: {
    right: "center",
    bottom: 0,
    orient: "horizontal"
  },
  series: {
    selectedMode: "single",
    center: ["42%", "60%"],
    type: "pie",
    avoidLabelOverlap: false,
    label: {
      show: false
    },
    labelLine: {
      show: true
    },
    data: []
  }
};
var PieChart = ({
  width,
  height,
  data: data3,
  color,
  config,
  hideLegends = false,
  onClick,
  selectedOrganizationUnits
}) => {
  const suppressLegendSelection = (0, import_react2.useCallback)(
    (eChart, e) => {
      eChart.setOption({ animation: false });
      eChart.dispatchAction({
        type: "legendSelect",
        name: e.name
      });
      eChart.setOption({ animation: true });
      if (onClick) {
        eChart.dispatchAction({ type: "toggleSelect", name: e.name });
        onClick(eChart, e);
      }
    },
    [selectedOrganizationUnits, onClick]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
    EChart_default,
    {
      opt: __spreadProps(__spreadValues(__spreadProps(__spreadValues({}, option), {
        width,
        height
      }), config), {
        legend: __spreadProps(__spreadValues(__spreadValues({}, option.legend), config == null ? void 0 : config.legend), {
          show: !hideLegends
        }),
        series: __spreadProps(__spreadValues(__spreadValues({
          title: "Here is it.",
          color
        }, option.series), config == null ? void 0 : config.series), {
          data: data3
        })
      }),
      onClick,
      suppressLegendSelection
    }
  );
};
var PieChart_default = PieChart;

// src/components/BarChart/index.tsx
var import_moment = __toESM(require("moment"));
var import_jsx_runtime7 = require("react/jsx-runtime");
var option2 = {
  tooltip: {
    show: false,
    trigger: "axis",
    axisPointer: {
      type: "shadow"
    },
    formatter: (params) => {
      let tar;
      if (params[1].value !== "-") {
        tar = params[1];
      } else {
        tar = params[0];
      }
      return tar.name + "<br/>" + tar.seriesName + " : " + (0, import_moment.default)(tar.value).format("MM/DD");
    }
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true
  },
  xAxis: {
    type: "value",
    axisLabel: {
      rotate: 45,
      formatter: (value) => (0, import_moment.default)(value).format("YYYY/MM")
    }
  },
  series: []
};
var BarChart = ({
  height,
  width,
  series,
  categoryData,
  min,
  max
}) => /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
  EChart_default,
  {
    height: height ? `${height}px` : void 0,
    width: width ? `${width}px` : void 0,
    opt: __spreadProps(__spreadValues({}, option2), {
      xAxis: __spreadProps(__spreadValues({}, option2.xAxis), {
        min,
        max
      }),
      yAxis: {
        type: "category",
        splitLine: { show: false },
        data: categoryData || [],
        axisLabel: {
          formatter: (value) => value.length > 25 ? value.slice(0, 22) + "..." : value
        }
      },
      series
    })
  }
);
var BarChart_default = BarChart;

// src/components/LineChart/index.tsx
var echarts2 = __toESM(require("echarts"));
var import_jsx_runtime8 = require("react/jsx-runtime");
var base = +new Date(2016, 9, 3);
var oneDay = 24 * 3600 * 1e3;
var valueBase = Math.random() * 300;
var valueBase2 = Math.random() * 50;
var data = [];
var data2 = [];
for (let i = 1; i < 10; i++) {
  const now = new Date(base += oneDay);
  const dayStr = [now.getFullYear(), now.getMonth() + 1, now.getDate()].join(
    "-"
  );
  valueBase = Math.round((Math.random() - 0.5) * 20 + valueBase);
  valueBase <= 0 && (valueBase = Math.random() * 300);
  data.push([dayStr, valueBase]);
  valueBase2 = Math.round((Math.random() - 0.5) * 20 + valueBase2);
  valueBase2 <= 0 && (valueBase2 = Math.random() * 50);
  data2.push([dayStr, valueBase2]);
}
var option3 = {
  legend: {
    top: "bottom",
    data: ["Intention"]
  },
  tooltip: {
    show: true
  },
  xAxis: {
    type: "time",
    axisPointer: {
      value: "2016-10-7",
      snap: true,
      lineStyle: {
        color: "#7581BD",
        width: 2
      },
      label: {
        show: true,
        formatter: function(params) {
          return echarts2.format.formatTime("yyyy-MM-dd", params.value);
        },
        backgroundColor: "#7581BD"
      }
    },
    splitLine: {
      show: true
    }
  },
  yAxis: {
    type: "value",
    axisTick: {
      inside: true
    },
    splitLine: {
      show: false
    },
    axisLabel: {
      inside: true,
      formatter: "{value}\n"
    },
    z: 10
  },
  grid: {
    top: 20,
    left: 5,
    right: 5,
    height: 160,
    bottom: 50
  },
  dataZoom: [
    {
      type: "inside",
      throttle: 50
    }
  ],
  series: [
    {
      name: "Fake Data",
      type: "line",
      smooth: true,
      symbol: "circle",
      symbolSize: 5,
      sampling: "average",
      itemStyle: {
        color: "#0770FF"
      },
      stack: "a",
      areaStyle: {
        color: new echarts2.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(58,77,233,0.8)"
          },
          {
            offset: 1,
            color: "rgba(58,77,233,0.3)"
          }
        ])
      },
      data
    },
    {
      name: "Fake Data",
      type: "line",
      smooth: true,
      stack: "a",
      symbol: "circle",
      symbolSize: 5,
      sampling: "average",
      itemStyle: {
        color: "#F2597F"
      },
      data: data2
    }
  ]
};
var LineChart = ({ height, width }) => /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
  EChart_default,
  {
    height: `${!height ? void 0 : height}px`,
    width: `${!width ? void 0 : width}px`,
    opt: __spreadProps(__spreadValues({}, option3), {
      grid: {
        top: 20,
        left: 5,
        right: 5,
        bottom: 50
      }
    })
  }
);
var LineChart_default = LineChart;

// src/components/Drawer/index.tsx
var import_outline = require("@heroicons/react/24/outline");
var import_jsx_runtime9 = require("react/jsx-runtime");
function Drawer({
  children,
  isOpen,
  setIsOpen,
  title,
  className
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(
    "main",
    {
      className: "fixed overflow-hidden z-[999] bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out" + (isOpen ? " transition-opacity opacity-100 duration-500 translate-x-0  " : " transition-all duration-500 opacity-0 translate-x-full  "),
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
          "section",
          {
            className: "w-screen max-w-xs right-0 absolute bg-white h-full           shadow-xl delay-400 duration-500 ease-in-out transition-all transform" + (isOpen ? " translate-x-0 " : " translate-x-full ") + `${className || ""}`,
            children: /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(
              "article",
              {
                className: `relative w-screen max-w-xs pb-10 flex flex-col overflow-y-scroll h-full ${className || ""}`,
                children: [
                  /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)("header", { className: "text-gray-900 p-4 font-semibold text-lg flex justify-between items-start", children: [
                    /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("span", { children: title || "Filters" }),
                    /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)("button", { onClick: () => setIsOpen(false), children: [
                      " ",
                      /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_outline.XMarkIcon, { className: "w-4 h-4 stroke-2" }),
                      " "
                    ] })
                  ] }),
                  children
                ]
              }
            )
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
          "section",
          {
            className: "w-screen h-full cursor-pointer",
            onClick: () => {
              setIsOpen(false);
            }
          }
        )
      ]
    }
  );
}

// src/components/LabelChart/index.tsx
var import_jsx_runtime10 = require("react/jsx-runtime");
var option4 = {
  title: {
    subtext: "Fake Data",
    left: "center"
  },
  tooltip: {
    trigger: "item"
  },
  series: [
    {
      type: "pie",
      radius: "65%",
      center: ["50%", "50%"],
      selectedMode: "single",
      data: [
        { value: 10, name: "Project A" },
        { value: 20, name: "Project B" },
        { value: 70, name: "Project C" }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)"
        }
      },
      labelLine: {
        show: false
      }
    }
  ]
};
var LabelChart = ({ height, width }) => /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(EChart_default, { height: `${height}px`, width: `${width}px`, opt: option4 });
var LabelChart_default = LabelChart;

// src/_constant/index.ts
var PRIMARY_COLOR_CODE = {
  25: "#e0ffe1",
  50: "#b9ffbb",
  100: "#b6e1bc",
  200: "#1ab394",
  300: "#6CE9A6",
  400: "#93d39c",
  500: "#64c171",
  600: "#45ad54",
  700: "#027A48",
  800: "#005e02",
  900: "#054F31"
};
var WARNING_COLOR_CODE = {
  25: "#FFFCF5",
  50: "#FFFAEB",
  100: "#FEF0C7",
  200: "#FEDF89",
  300: "#FEC84B",
  400: "#FDB022",
  500: "#F79009",
  600: "#DC6803",
  700: "#027A48",
  800: "#93370D",
  900: "#7A2E0E"
};
var ERROR_COLOR_CODE = {
  25: "#FFFBFA",
  50: "#FEF3F2",
  100: "#FEE4E2",
  200: "#FECDCA",
  300: "#FDA29B",
  400: "#F97066",
  500: "#ff0000",
  600: "#D92D20",
  700: "#B42318",
  800: "#912018",
  900: "#7A271A"
};
var SUCCESS_COLOR_CODE = {
  25: "#FCFAFF",
  50: "#F9F5FF",
  100: "#F4EBFF",
  200: "#E9D7FE",
  300: "#D6BBFB",
  400: "#B692F6",
  500: "#9E77ED",
  600: "#7F56D9",
  700: "#6941C6",
  800: "#53389E",
  900: "#42307D"
};

// src/components/GaugeChart/index.tsx
var import_jsx_runtime11 = require("react/jsx-runtime");
var defaultData = [
  {
    value: 0,
    name: "",
    title: {
      show: false
    },
    detail: {
      valueAnimation: true,
      offsetCenter: ["0%", "0%"]
    }
  }
];
var option5 = {
  tooltip: {
    trigger: "item",
    className: "z-50",
    position: ["50%", "20%"]
  },
  color: PRIMARY_COLOR_CODE[800],
  series: {
    type: "gauge",
    startAngle: 90,
    endAngle: -270,
    pointer: {
      show: false
    },
    animation: false,
    progress: {
      show: true,
      overlap: false,
      roundCap: false,
      clip: false,
      itemStyle: {
        borderWidth: 0
      }
    },
    axisLine: {
      lineStyle: {
        width: 15
      }
    },
    splitLine: {
      show: false,
      distance: 0,
      length: 10
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: false,
      distance: 50
    },
    data: defaultData,
    title: {
      fontSize: 14
    }
  }
};
var colors3 = {
  primary: PRIMARY_COLOR_CODE[800],
  error: ERROR_COLOR_CODE[500],
  warning: WARNING_COLOR_CODE[500],
  success: SUCCESS_COLOR_CODE[300]
};
var GaugeChart = ({
  height,
  width,
  data: data3,
  color,
  lineWidth,
  fontSize,
  centerLabel,
  tooltip,
  animation
}) => /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(
  EChart_default,
  {
    height,
    width,
    opt: __spreadProps(__spreadValues({}, option5), {
      tooltip: tooltip ? option5.tooltip : false,
      color: colors3[color],
      series: __spreadProps(__spreadValues({}, option5.series), {
        animation: animation || true,
        axisLine: {
          lineStyle: {
            width: lineWidth || 15
          }
        },
        data: [
          {
            value: data3.value ? parseInt(data3.value + "").toFixed() : 0,
            name: data3.name,
            title: {
              show: false
            },
            detail: {
              show: centerLabel === false ? false : true,
              fontSize: fontSize || 12,
              valueAnimation: true,
              offsetCenter: ["0%", "0%"]
            }
          }
        ],
        detail: {
          width: 25,
          height: 15,
          fontSize: 12,
          color: colors3[color],
          borderColor: colors3[color],
          borderRadius: 0,
          borderWidth: 0,
          formatter: "{value}"
        }
      })
    })
  }
);
var GaugeChart_default = GaugeChart;

// src/components/BreadCrumb/index.tsx
var import_react_router_dom = require("react-router-dom");
var import_outline2 = require("@heroicons/react/24/outline");
var import_jsx_runtime12 = require("react/jsx-runtime");
var BreadCrumb = ({ items, className }) => /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("nav", { "aria-label": "Breadcrumb", className, children: /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(
  "ol",
  {
    role: "list",
    className: "flex max-w-2xl items-center space-x-2 flex-row-reverse",
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("li", { className: "ml-2 text-sm", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "flex items-center", children: [
        items.length > 0 && /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_outline2.ChevronRightIcon, { className: "w-3 text-gray-400 rotate-180" }),
        /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
          import_react_router_dom.NavLink,
          {
            to: "/",
            "aria-current": "page",
            className: "ml-2 text-sm font-medium text-gray-600 hover:text-primary-600",
            children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_outline2.HomeIcon, { className: "w-4" })
          }
        )
      ] }) }),
      items.map(
        (breadcrumb, index) => index + 1 === items.length ? /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
          "li",
          {
            className: "text-sm font-medium text-primary-600 hover:text-primary-700",
            children: breadcrumb.name
          },
          breadcrumb.name
        ) : /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("li", { children: /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "flex items-center", children: [
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_outline2.ChevronRightIcon, { className: "w-3 text-gray-400 rotate-180" }),
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
            import_react_router_dom.NavLink,
            {
              to: breadcrumb.href,
              className: "ml-2 text-sm font-medium text-gray-600 hover:text-primary-600",
              children: breadcrumb.name
            }
          )
        ] }) }, breadcrumb.name)
      )
    ]
  }
) });
var BreadCrumb_default = BreadCrumb;

// src/components/Spinner/index.tsx
var import_jsx_runtime13 = require("react/jsx-runtime");
var sizes5 = {
  sm: "w-4",
  md: "w-5",
  lg: "w-6",
  xl: "w-10"
};
var Spinner = ({ size = "md", color = "text-white" }) => /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)(
  "svg",
  {
    className: `animate-spin -ml-1 mr-3 ${color} ${sizes5[size]}`,
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
        "circle",
        {
          className: "opacity-25",
          cx: "12",
          cy: "12",
          r: "10",
          stroke: "currentColor",
          strokeWidth: "4"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
        "path",
        {
          className: "opacity-75",
          fill: "currentColor",
          d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 \n        5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        }
      )
    ]
  }
);
var Spinner_default = Spinner;

// src/components/Tooltip/index.tsx
var import_jsx_runtime14 = require("react/jsx-runtime");
var Tooltip = ({ text, toolTipText, className, maxChar }) => /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_jsx_runtime14.Fragment, { children: toolTipText ? /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)("span", { className: `tooltip ${className}`, children: [
  maxChar && /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_jsx_runtime14.Fragment, { children: text.length > maxChar ? `${text.substring(0, maxChar)}...` : text }),
  !maxChar && /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_jsx_runtime14.Fragment, { children: text }),
  /* @__PURE__ */ (0, import_jsx_runtime14.jsx)("span", { className: "tooltiptext", children: toolTipText })
] }) : /* @__PURE__ */ (0, import_jsx_runtime14.jsx)("span", { className: `${className}`, children: text }) });
var Tooltip_default = Tooltip;

// src/components/LogoLoader/index.tsx
var import_jsx_runtime15 = require("react/jsx-runtime");
var LogoLoader = () => /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)("div", { className: "inline-flex items-center", children: [
  /* @__PURE__ */ (0, import_jsx_runtime15.jsx)("h2", { className: "text-[20px] duration-300 font-semibold text-gray-600 mr-4", children: "Amplon.io" }),
  /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(Spinner_default, { size: "lg", color: "primary-600" })
] });
var LogoLoader_default = LogoLoader;

// src/components/ReactSelect/index.tsx
var import_react_select = __toESM(require("react-select"));
var import_animated = __toESM(require("react-select/animated"));
var import_jsx_runtime16 = require("react/jsx-runtime");
var animatedComponents = (0, import_animated.default)();
function ReactSelect(_a) {
  var _b = _a, {
    closeMenuOnSelect,
    defaultValue,
    options,
    onChange,
    isClearable,
    hasError
  } = _b, rest = __objRest(_b, [
    "closeMenuOnSelect",
    "defaultValue",
    "options",
    "onChange",
    "isClearable",
    "hasError"
  ]);
  return /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
    import_react_select.default,
    __spreadProps(__spreadValues({
      className: "react-select-container"
    }, rest), {
      closeMenuOnSelect,
      components: animatedComponents,
      defaultValue,
      options,
      isClearable,
      onChange,
      theme: (theme) => __spreadProps(__spreadValues({}, theme), {
        colors: __spreadProps(__spreadValues({}, theme.colors), {
          primary: "#027A48",
          primary25: "#A6F4C5",
          primary50: "#6CE9A6",
          primary75: "#12B76A"
        })
      }),
      classNames: {
        option: (props) => {
          const { isSelected } = props;
          return `hover:bg-primary-700 hover:text-white ${isSelected && "bg-primary-700 text-white"} `;
        },
        multiValue: (props) => `${""} bg-primary-700`
      },
      styles: {
        option: (styles, { isSelected, isDisabled, isFocused }) => __spreadProps(__spreadValues({}, styles), {
          backgroundColor: isDisabled ? void 0 : isSelected ? "#027A48" : isFocused ? void 0 : void 0
        }),
        control: (styles, { isFocused }) => __spreadProps(__spreadValues({}, styles), {
          height: "42px",
          boxShadow: hasError && isFocused ? "0 0 0 3px rgba(240, 68, 56, 0.2)" : isFocused ? "0 0 0 3px rgba(50, 213, 131, 0.2)" : void 0,
          borderColor: hasError ? "#F04438" : isFocused ? "#12B76A" : void 0
        }),
        multiValue: (styles, { data: data3 }) => __spreadProps(__spreadValues({}, styles), {
          backgroundColor: "#027A48"
        }),
        multiValueLabel: (styles, { data: data3 }) => __spreadProps(__spreadValues({}, styles), {
          color: "#fff"
        }),
        multiValueRemove: (styles, { data: data3 }) => __spreadProps(__spreadValues({}, styles), {
          color: "#fff"
        })
      },
      maxMenuHeight: 150
    })
  );
}

// src/components/RowLoader/index.tsx
var import_jsx_runtime17 = require("react/jsx-runtime");
var RowLoader = () => /* @__PURE__ */ (0, import_jsx_runtime17.jsxs)("div", { className: "animate-pulse min-w-[500px]", children: [
  /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("div", { className: "h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-1/2 mb-6" }),
  /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("div", { className: "h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-1/3 mb-5" }),
  /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("div", { className: "h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-5" }),
  /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("div", { className: "h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-4/5 mb-5" }),
  /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("div", { className: "h-4 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-5" }),
  /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("div", { className: "h-4 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]" }),
  /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("span", { className: "sr-only", children: "Loading..." })
] });
var RowLoader_default = RowLoader;

// src/components/common/Logo/index.tsx
var import_jsx_runtime18 = require("react/jsx-runtime");
var sizes6 = {
  sm: "w-10 h-10",
  md: "w-12 h-12"
};
var Logo = ({ className = "", type = "circle", size = "md" }) => {
  if (type === "circle") {
    return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
      "img",
      {
        className: `${sizes6[size]} ${className}`,
        src: "/img/logo_light.png",
        alt: "Amplon"
      }
    );
  } else {
    return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
      "img",
      {
        className: `w-auto max-h-14 ${className}`,
        src: "/img/amplonio_logo_pos_RGB.png",
        alt: "Amplon"
      }
    );
  }
};
var Logo_default = Logo;

// src/components/common/Box/index.tsx
var import_react3 = __toESM(require("react"));
var import_jsx_runtime19 = require("react/jsx-runtime");
var Box = import_react3.default.forwardRef(
  ({ children, className }, ref) => /* @__PURE__ */ (0, import_jsx_runtime19.jsx)("div", { ref, className: `p-4 rounded-md shadow-md bg-white ${className}`, children })
);
var Box_default = Box;

// src/components/common/Badge/index.tsx
var import_jsx_runtime20 = require("react/jsx-runtime");
var Badge = ({ text, variant = "success", className }) => {
  const classname = (() => {
    switch (variant) {
      case "success":
        return "text-primary-700 bg-primary-50";
      case "danger":
        return "text-error-700 bg-error-50";
      case "default":
        return "text-gray-700 bg-gray-200";
    }
  })();
  return /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
    "span",
    {
      className: `rounded-2xl font-semibold ${classname} py-2 px-3 text-xs ${className}`,
      children: text
    }
  );
};
var Badge_default = Badge;

// src/components/common/Typography/index.tsx
var import_jsx_runtime21 = require("react/jsx-runtime");
var sizeVarient = {
  sm: "text-xs md:text-sm",
  md: "text-base font-semibold",
  lg: "text-base",
  xl: "text-base",
  "2xl": "text-base",
  h1: "text-3xl font-semibold",
  h2: "text-2xl font-semibold",
  h3: "text-lg md:text-xl font-semibold",
  h4: "text-sm md:text-lg  font-semibold",
  h5: "text-base font-semibold",
  h6: "text-xs md:text-sm font-semibold"
};
var Typography = ({
  children,
  varient = "md" /* MD */,
  className = ""
}) => /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("p", { className: `text-gray-900 ${sizeVarient[varient]} ${className}`, children });
var Typography_default = Typography;

// src/components/common/PageHeading/index.tsx
var import_jsx_runtime22 = require("react/jsx-runtime");
function PageHeading({
  title,
  subTitle,
  actionMenu
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)("div", { className: "flex py-4 px-3 items-center", children: [
    /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)("div", { className: "flex flex-col gap-y-2 grow", children: [
      /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
        Typography_default,
        {
          varient: "h3" /* H3 */,
          className: "text-gray-900 text-lg",
          children: title
        }
      ),
      subTitle && /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
        Typography_default,
        {
          varient: "h6" /* H6 */,
          className: "text-gray-600 text-xs font-normal",
          children: subTitle
        }
      )
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime22.jsx)("div", { children: actionMenu })
  ] });
}

// src/components/common/OverlayLoader/index.tsx
var import_jsx_runtime23 = require("react/jsx-runtime");
function OverlayLoader() {
  return /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
    "div",
    {
      className: "flex justify-center items-center fixed \n      left-0 top-0 w-screen h-screen bg-black bg-opacity-50 z-[99999]",
      children: /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(Spinner_default, { size: "xl", color: "text-primary-900" })
    }
  );
}

// src/components/common/Label/index.tsx
var import_outline3 = require("@heroicons/react/24/outline");
var import_react_tooltip = require("react-tooltip");
var import_jsx_runtime24 = require("react/jsx-runtime");
var Label = ({ label, information, name }) => /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)(import_jsx_runtime24.Fragment, { children: [
  /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
    "label",
    {
      id: `label-${name || ""}`,
      htmlFor: name,
      className: "block text-sm font-medium text-gray-700",
      children: /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)("span", { className: "flex space-x-4 font-semibold text-sm ", children: [
        label,
        "\xA0",
        information && /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
          import_outline3.InformationCircleIcon,
          {
            id: `info-${name}`,
            "data-tooltip-content": information,
            className: "cursor-pointer",
            height: 15,
            width: 15
          }
        )
      ] })
    }
  ),
  information && /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(import_react_tooltip.Tooltip, { anchorId: `info-${name}` })
] });
var Label_default = Label;

// src/components/Form/CheckBox/index.tsx
var import_react4 = __toESM(require("react"));
var import_jsx_runtime25 = require("react/jsx-runtime");
var CheckBox = import_react4.default.forwardRef(
  (_a, ref) => {
    var _b = _a, { name, indeterminate, label, error } = _b, otherProps = __objRest(_b, ["name", "indeterminate", "label", "error"]);
    return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("label", { className: "flex py-2.5 gap-x-2 items-center text-xs font-semibold text-gray-600 cursor-pointer", children: [
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
          "input",
          __spreadValues({
            ref,
            type: "checkbox",
            name,
            id: name,
            className: `checkbox ${indeterminate && "indeterminate"} h-4 w-4 rounded bg-white
            border border-gray-300 text-primary-600 cursor-pointer checked:bg-primary-700 focus:ring-primary-500`
          }, otherProps)
        ),
        /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("span", { children: label })
      ] }),
      error && /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
        "p",
        {
          className: `text-sm mt-1.5 ${error ? "text-error-500" : "text-gray-600"}`,
          children: error.message
        }
      )
    ] });
  }
);
var CheckBox_default = CheckBox;

// src/components/Form/FormInputGroup/index.tsx
var import_solid = require("@heroicons/react/20/solid");
var import_react_tooltip2 = require("react-tooltip");
var import_jsx_runtime26 = require("react/jsx-runtime");
var FormInputGroup = ({
  name,
  label,
  children,
  information,
  className
}) => /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_jsx_runtime26.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("div", { className: `mb-4 ${className}`, children: [
  /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    "label",
    {
      id: `label-${name}`,
      htmlFor: name,
      className: "block text-sm font-medium text-gray-700",
      children: /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)("span", { className: "flex space-x-4 font-semibold text-sm", children: [
        label,
        "\xA0",
        information && /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
          import_solid.InformationCircleIcon,
          {
            id: `info-${name}`,
            "data-tooltip-content": information,
            className: "cursor-pointer",
            height: 15,
            width: 15
          }
        )
      ] })
    }
  ),
  /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { className: "relative mt-1", children }),
  information && /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_react_tooltip2.Tooltip, { anchorId: `info-${name}` })
] }) });
var FormInputGroup_default = FormInputGroup;

// src/components/Form/SelectInput/index.tsx
var import_react5 = __toESM(require("react"));
var import_jsx_runtime27 = require("react/jsx-runtime");
var sizes7 = {
  sm: "h-8",
  md: "h-10",
  lg: "h-14"
};
var SelectInput = import_react5.default.forwardRef(
  (_a, ref) => {
    var _b = _a, {
      name,
      options,
      variant = "md" /* MD */,
      error,
      disabled,
      className
    } = _b, otherProps = __objRest(_b, [
      "name",
      "options",
      "variant",
      "error",
      "disabled",
      "className"
    ]);
    return /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(import_jsx_runtime27.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("div", { className: `${variant ? sizes7[variant] : sizes7.md}`, children: /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
        "select",
        __spreadProps(__spreadValues({
          ref,
          disabled,
          name,
          id: name,
          className: `w-full box-border flex flex-row items-center
            ${variant === "sm" /* SM */ ? " py-1 pl-2 pr-8 text-xs" : " py-2 px-2"}
            focus:ring-0 rounded border border-solid focus:shadow-outline ${error ? "border-error-500 focus:border-error-500 focus:shadow-input-error" : "border-gray-300 focus:outline-0 focus:border-primary-500                    focus:shadow-primary-400/20 focus:shadow-input"} h-inherit ${disabled ? "bg-gray-100" : ""} ${className}`
        }, otherProps), {
          children: options.map((item) => /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("option", { className: "text-base", value: item.value, children: item.label }, item.value))
        })
      ) }),
      error && /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
        "p",
        {
          className: `text-sm mt-1.5 ${error ? "text-error-500" : "text-gray-600"}`,
          children: error.message
        }
      )
    ] });
  }
);
var SelectInput_default = SelectInput;

// src/components/Form/TextInput/index.tsx
var import_react6 = __toESM(require("react"));
var import_outline4 = require("@heroicons/react/24/outline");
var import_jsx_runtime28 = require("react/jsx-runtime");
var sizes8 = {
  xs: "h-8",
  sm: "h-10",
  md: "h-12",
  lg: "h-14"
};
var TextInput = import_react6.default.forwardRef(
  (_a, ref) => {
    var _b = _a, {
      name,
      variant,
      type,
      error,
      helperText,
      hintText,
      readOnly,
      className,
      isSearchField
    } = _b, otherProps = __objRest(_b, [
      "name",
      "variant",
      "type",
      "error",
      "helperText",
      "hintText",
      "readOnly",
      "className",
      "isSearchField"
    ]);
    const [showPassword, setShowPassword] = (0, import_react6.useState)(false);
    return /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(import_jsx_runtime28.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)("div", { className: `relative ${variant ? sizes8[variant] : sizes8.sm}`, children: [
        isSearchField && /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_jsx_runtime28.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_outline4.MagnifyingGlassIcon, { className: "cursor-pointer text-gray-400 stroke-2 absolute inset-y-0 left-0 flex items-center h-inherit w-8 pl-3" }) }),
        /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
          "input",
          __spreadValues({
            ref,
            type: showPassword ? "text" : type || "text",
            name,
            id: name,
            readOnly,
            className: `w-full box-border flex flex-row items-center gap-2 py-2 pl-3 ${type !== "date" && type !== "number" ? "pr-8" : "pr-3"} ${type === "file" && "relative appearance-none pl-[120px] file:w-[110px] cursor-pointer                file:cursor-pointer file:text-gray-700 file:px-4 file:border-0                file:bg-gray-200 file:absolute file:left-0 file:top-0 file:h-full"} focus:ring-0 focus:shadow-outline rounded border border-solid outline-none ${error ? "border-error-500 focus:border-error-500 focus:shadow-input-error " : "border-gray-300 focus:border-primary-500 focus:shadow-primary-400/20 focus:shadow-input "} h-inherit ${readOnly ? "bg-gray-100" : ""} ${isSearchField ? "pl-9" : ""} ${className}`
          }, otherProps)
        ),
        type !== "password" && (!type || type !== "date") && helperText && /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
          import_outline4.QuestionMarkCircleIcon,
          {
            className: `absolute inset-y-0 right-0 flex items-center h-inherit w-8 ${error ? "text-error-500" : "text-gray-400"} pr-3 `
          }
        ),
        type === "password" && /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_jsx_runtime28.Fragment, { children: showPassword ? /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
          import_outline4.EyeSlashIcon,
          {
            onClick: () => setShowPassword(false),
            className: "cursor-pointer absolute inset-y-0 right-0 flex items-center h-inherit w-8 pr-3"
          }
        ) : /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
          import_outline4.EyeIcon,
          {
            onClick: () => setShowPassword(true),
            className: "cursor-pointer absolute inset-y-0 right-0 flex items-center h-inherit w-8 pr-3"
          }
        ) })
      ] }),
      error ? /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
        "p",
        {
          className: `text-sm mt-1.5 ${error ? "text-error-500" : "text-gray-600"}`,
          children: error.message
        }
      ) : /* @__PURE__ */ (0, import_jsx_runtime28.jsx)("p", { className: "text-xs mt-1.5 text-gray-500", children: hintText })
    ] });
  }
);
var TextInput_default = TextInput;

// src/components/Form/TextArea/index.tsx
var import_react7 = __toESM(require("react"));
var import_jsx_runtime29 = require("react/jsx-runtime");
var sizes9 = {
  sm: "h-10",
  md: "h-12",
  lg: "h-14"
};
var TextArea = import_react7.default.forwardRef(
  (_a, ref) => {
    var _b = _a, {
      name,
      variant,
      className,
      readOnly,
      helperText,
      error
    } = _b, otherProps = __objRest(_b, [
      "name",
      "variant",
      "className",
      "readOnly",
      "helperText",
      "error"
    ]);
    return /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_jsx_runtime29.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime29.jsx)("div", { className: `${variant ? sizes9[variant] : sizes9.xl}`, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
        "textarea",
        __spreadValues({
          rows: 3,
          readOnly,
          ref,
          name,
          id: name,
          className: `w-full box-border flex flex-row items-center
            gap-2 py-2 pl-3 pr-8 focus:ring-0 focus:shadow-outline rounded border border-solid ${error ? "border-error-500 focus:border-error-500 focus:shadow-input-error" : "border-gray-300 focus:outline-0 focus:border-primary-500                    focus:shadow-primary-400/20 focus:shadow-input"} h-inherit ${readOnly ? "bg-gray-100" : ""} ${className}`,
          placeholder: otherProps.placeholder || ""
        }, otherProps)
      ) }),
      error ? /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
        "p",
        {
          className: `text-sm mt-1.5 ${error ? "text-error-500" : "text-gray-600"}`,
          children: error.message
        }
      ) : /* @__PURE__ */ (0, import_jsx_runtime29.jsx)("p", { children: helperText })
    ] });
  }
);
var TextArea_default = TextArea;

// src/components/Form/FileInput/index.tsx
var import_react8 = __toESM(require("react"));
var import_jsx_runtime30 = require("react/jsx-runtime");
var FileInput = import_react8.default.forwardRef(
  ({ name, error, onFileSelect }, ref) => {
    const [fileName, setFileName] = (0, import_react8.useState)(null);
    const onfileChange = (e) => {
      var _a;
      const target = e.target;
      if (target && (target == null ? void 0 : target.files) && ((_a = target == null ? void 0 : target.files) == null ? void 0 : _a.length) > 0) {
        setFileName(target.files[0]["name"]);
        onFileSelect && onFileSelect(target.files);
      }
    };
    return /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_jsx_runtime30.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("div", { ref, className: "relative", children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("div", { className: "flex justify-center p-4 border-2 border-gray-300 border-dashed rounded-md", children: /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)("div", { className: "text-center", children: [
        /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
          "svg",
          {
            className: "mx-auto h-12 w-12 text-gray-400",
            stroke: "currentColor",
            fill: "none",
            viewBox: "0 0 48 48",
            children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
              "path",
              {
                d: "M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 \n                  0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 \n                  32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02",
                strokeWidth: "2",
                strokeLinecap: "round",
                strokeLinejoin: "round"
              }
            )
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("p", { className: "mt-1 text-sm text-gray-600", children: /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(
          "label",
          {
            className: "font-medium text-indigo-600 hover:text-indigo-500 \n                  cursor-pointer focus:outline-none focus:underline transition \n                  duration-150 ease-in-out",
            children: [
              fileName || "Upload a file",
              /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
                "input",
                {
                  type: "file",
                  onChange: onfileChange,
                  name,
                  id: name,
                  className: "hidden"
                }
              )
            ]
          }
        ) })
      ] }) }) }),
      error && /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
        "p",
        {
          className: `text-sm mt-1.5 ${error ? "text-error-500" : "text-gray-600"}`,
          children: error.message
        }
      )
    ] });
  }
);
var FileInput_default = FileInput;

// src/components/Form/RadioInput/index.tsx
var import_react9 = __toESM(require("react"));
var import_jsx_runtime31 = require("react/jsx-runtime");
var RadioInput = import_react9.default.forwardRef(
  (_a, ref) => {
    var _b = _a, {
      name,
      label,
      className,
      labelClass,
      disabled,
      error
    } = _b, otherProps = __objRest(_b, [
      "name",
      "label",
      "className",
      "labelClass",
      "disabled",
      "error"
    ]);
    return /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)(import_jsx_runtime31.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)(
        "label",
        {
          className: `flex py-2.5 gap-x-2 items-center text-xs font-semibold text-gray-600 cursor-pointer ${disabled && "opacity-70 cursor-not-allowed"} ${labelClass}`,
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
              "input",
              __spreadValues({
                ref,
                type: "radio",
                name,
                id: name,
                disabled,
                className: `h-4 w-4 rounded-full p-[5px] border accent-gray-300
          checked:accent-primary-600 border-gray-300 text-primary-600
          focus:ring-primary-600 ${className}`
              }, otherProps)
            ),
            /* @__PURE__ */ (0, import_jsx_runtime31.jsx)("span", { children: label })
          ]
        }
      ),
      error && /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
        "p",
        {
          className: `text-sm mt-1.5 ${error ? "text-error-500" : "text-gray-600"}`,
          children: error.message
        }
      )
    ] });
  }
);
var RadioInput_default = RadioInput;

// src/components/DataTable/DataTable/index.tsx
var import_react13 = require("react");
var import_react_toastify = require("react-toastify");
var import_react_table4 = require("@tanstack/react-table");

// src/components/DataTable/TableHeader/index.tsx
var import_solid2 = require("@heroicons/react/20/solid");
var import_react_table = require("@tanstack/react-table");
var import_jsx_runtime32 = require("react/jsx-runtime");
var tableHeadWidth = {
  "20": "w-[20px]"
};
var TableHeader = ({ table }) => /* @__PURE__ */ (0, import_jsx_runtime32.jsx)("thead", { className: "text-left", children: table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ (0, import_jsx_runtime32.jsx)("tr", { children: headerGroup.headers.map((header) => {
  var _a;
  return /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
    "th",
    {
      className: `px-5 py-2.5 text-xs bg-white font-medium border-b border-gray-200 py-2 text-gray-600 ${tableHeadWidth[header.column.getSize().toString()] ? tableHeadWidth[header.column.getSize().toString()] : ""}`,
      children: header.isPlaceholder ? null : /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(
        "span",
        {
          className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
          onClick: header.column.getToggleSortingHandler(),
          children: [
            (0, import_react_table.flexRender)(
              header.column.columnDef.header,
              header.getContext()
            ),
            (_a = {
              asc: /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(import_solid2.ArrowSmallUpIcon, { className: "inline w-6" }),
              desc: /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(import_solid2.ArrowSmallDownIcon, { className: "inline w-6" })
            }[header.column.getIsSorted()]) != null ? _a : null
          ]
        }
      )
    },
    header.id
  );
}) }, headerGroup.id)) });
var TableHeader_default = TableHeader;

// src/components/DataTable/TableFooter/index.tsx
var import_react_table2 = require("@tanstack/react-table");
var import_jsx_runtime33 = require("react/jsx-runtime");
var TableFooter = ({ table }) => /* @__PURE__ */ (0, import_jsx_runtime33.jsx)("tfoot", { children: table.getFooterGroups().map((footerGroup) => /* @__PURE__ */ (0, import_jsx_runtime33.jsx)("tr", { children: footerGroup.headers.map((header) => /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(
  "th",
  {
    className: "border bg-gray-100 bg-gray-100 px-6 py-3 text-center",
    children: header.isPlaceholder ? null : (0, import_react_table2.flexRender)(header.column.columnDef.footer, header.getContext())
  },
  header.id
)) }, footerGroup.id)) });
var TableFooter_default = TableFooter;

// src/components/DataTable/TableBody/index.tsx
var import_react_table3 = require("@tanstack/react-table");
var import_react11 = require("react");

// src/components/DataTable/TableBodyCell/index.tsx
var import_react10 = __toESM(require("react"));
var import_jsx_runtime34 = require("react/jsx-runtime");
var TableBodyCell = import_react10.default.forwardRef(
  (_a, ref) => {
    var _b = _a, { children, className, rowSpan, colSpan } = _b, otherProps = __objRest(_b, ["children", "className", "rowSpan", "colSpan"]);
    return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
      "td",
      __spreadProps(__spreadValues({
        ref,
        rowSpan,
        colSpan,
        className: `border text-sm px-2 py-2 ${className}`
      }, otherProps), {
        children
      })
    );
  }
);
var TableBodyCell_default = TableBodyCell;

// src/components/DataTable/TableBody/index.tsx
var import_jsx_runtime35 = require("react/jsx-runtime");
var TableBody = ({
  table,
  expandComponent,
  expandInSameTable
}) => /* @__PURE__ */ (0, import_jsx_runtime35.jsx)("tbody", { className: "text-left", children: table.getRowModel().rows.map((row, index) => /* @__PURE__ */ (0, import_jsx_runtime35.jsxs)(import_react11.Suspense, { children: [
  /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
    "tr",
    {
      className: `border-b border-gray-200 ${index % 2 === 0 ? "bg-gray-50" : ""}`,
      children: row.getVisibleCells().map((cell) => /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(TableBodyCell_default, { className: "text-sm text-gray-600", children: (0, import_react_table3.flexRender)(cell.column.columnDef.cell, cell.getContext()) }, cell.id))
    },
    row.id
  ),
  row.getIsExpanded() && expandComponent && (expandInSameTable ? expandComponent({ row }) : /* @__PURE__ */ (0, import_jsx_runtime35.jsx)("tr", { children: /* @__PURE__ */ (0, import_jsx_runtime35.jsx)("td", { colSpan: row.getVisibleCells().length, children: expandComponent({ row }) }) }))
] }, row.id)) });
var TableBody_default = TableBody;

// src/components/DataTable/TablePagination/index.tsx
var import_outline5 = require("@heroicons/react/24/outline");

// src/components/DataTable/TablePaginateCell/index.tsx
var import_jsx_runtime36 = require("react/jsx-runtime");
var TablePaginateCell = (_a) => {
  var _b = _a, {
    children,
    disabled,
    active = false
  } = _b, props = __objRest(_b, [
    "children",
    "disabled",
    "active"
  ]);
  return /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(
    "a",
    __spreadProps(__spreadValues({
      "aria-current": "page",
      className: `  last:rounded-r-md first:rounded-l-md relative hidden
      text-center justify-center items-center w-[40px] h-[40px] border 
      border-gray-300 bg-white p-[10px] text-sm font-medium text-gray-500 
      hover:bg-gray-50 focus:z-20 md:inline-flex hover:text-gray-900 cursor-pointer ${active && "bg-gray-200 text-gray-800 "} ${disabled && "pointer-events-none bg-gray-100 opacity-50"}`
    }, props), {
      children
    })
  );
};
var TablePaginateCell_default = TablePaginateCell;

// src/components/DataTable/TablePagination/index.tsx
var import_jsx_runtime37 = require("react/jsx-runtime");
var TablePagination = ({
  table,
  pagination,
  total,
  paginationText
}) => {
  const pageSizeText = paginationText ? paginationText.pageSize : "Page Size:";
  const showingText = `${paginationText ? paginationText.showing : "Showing"} ${table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} ${paginationText ? paginationText.to : "to"} ${total < (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize ? total : (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize} ${paginationText ? paginationText.of : "of"} ${total} ${paginationText ? paginationText.entries : "entries"}`;
  return /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(import_jsx_runtime37.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)("div", { className: "flex items-center justify-between bg-white py-5 px-5", children: /* @__PURE__ */ (0, import_jsx_runtime37.jsxs)("div", { className: "sm:flex sm:flex-1 sm:items-center sm:justify-between", children: [
    /* @__PURE__ */ (0, import_jsx_runtime37.jsxs)("div", { className: "flex", children: [
      /* @__PURE__ */ (0, import_jsx_runtime37.jsx)("span", { className: "flex items-center gap-1 text-sm text-gray-700 ml-2 mr-2", children: pageSizeText }),
      /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
        SelectInput_default,
        {
          options: [10, 20, 50, 100, 200, 400].map((val) => ({
            label: val,
            value: val
          })),
          variant: "sm" /* SM */,
          value: table.getState().pagination.pageSize,
          onChange: (e) => {
            table.setPageSize(Number(e.target.value));
          }
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime37.jsx)("span", { className: "flex items-center gap-1 text-xs font-semibold text-gray-700 ml-2", children: showingText })
    ] }),
    total > table.getState().pagination.pageSize && /* @__PURE__ */ (0, import_jsx_runtime37.jsxs)(import_jsx_runtime37.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime37.jsxs)("div", { className: "flex flex-1 justify-between sm:hidden", children: [
        /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
          "a",
          {
            onClick: () => table.previousPage(),
            className: "relative inline-flex items-center rounded-md\n                  border border-gray-300 bg-white px-4 py-2 text-sm\n                  font-medium text-gray-700 hover:bg-gray-50",
            children: paginationText ? paginationText.previous : "Previous"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
          "a",
          {
            onClick: () => table.nextPage(),
            className: "relative ml-3 inline-flex items-center rounded-md\n                  border border-gray-300 bg-white px-4 py-2 text-sm\n                  font-medium text-gray-700 hover:bg-gray-50",
            children: paginationText ? paginationText.next : "Next"
          }
        )
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime37.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime37.jsxs)(
        "nav",
        {
          className: "isolate inline-flex -space-x-px rounded-md shadow-sm",
          "aria-label": "Pagination",
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
              TablePaginateCell_default,
              {
                disabled: !table.getCanPreviousPage(),
                onClick: () => table.setPageIndex(0),
                children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
                  import_outline5.ChevronDoubleLeftIcon,
                  {
                    className: "h-4 w-4 stroke-2",
                    "aria-hidden": "true"
                  }
                )
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
              TablePaginateCell_default,
              {
                disabled: !table.getCanPreviousPage(),
                onClick: () => table.previousPage(),
                children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
                  import_outline5.ChevronLeftIcon,
                  {
                    className: "h-4 w-4 stroke-2",
                    "aria-hidden": "true"
                  }
                )
              }
            ),
            table.getCanPreviousPage() && /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
              TablePaginateCell_default,
              {
                onClick: () => table.setPageIndex((index) => index - 1),
                children: pagination.pageIndex
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
              TablePaginateCell_default,
              {
                onClick: () => table.setPageIndex((index) => index),
                active: true,
                children: pagination.pageIndex + 1
              }
            ),
            table.getCanNextPage() && /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
              TablePaginateCell_default,
              {
                onClick: () => table.setPageIndex((index) => index + 1),
                children: pagination.pageIndex + 2
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
              TablePaginateCell_default,
              {
                disabled: !table.getCanNextPage(),
                onClick: () => table.nextPage(),
                children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
                  import_outline5.ChevronRightIcon,
                  {
                    className: "h-4 w-4 stroke-2",
                    "aria-hidden": "true"
                  }
                )
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
              TablePaginateCell_default,
              {
                disabled: !table.getCanNextPage(),
                onClick: () => table.setPageIndex(table.getPageCount() - 1),
                children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
                  import_outline5.ChevronDoubleRightIcon,
                  {
                    className: "h-4 w-4 stroke-2",
                    "aria-hidden": "true"
                  }
                )
              }
            )
          ]
        }
      ) })
    ] })
  ] }) }) });
};
var TablePagination_default = TablePagination;

// src/utils/helper.ts
var copyToClipboard = (ref) => {
  var _a;
  (_a = window.getSelection()) == null ? void 0 : _a.removeAllRanges();
  return new Promise((resolve, reject) => {
    var _a2, _b;
    const range = document.createRange();
    range.selectNode(ref.current);
    (_a2 = window.getSelection()) == null ? void 0 : _a2.addRange(range);
    try {
      document.execCommand("copy");
    } catch (err) {
      reject(false);
    }
    (_b = window.getSelection()) == null ? void 0 : _b.removeAllRanges();
    return resolve(true);
  });
};

// src/components/Form/SearchInput/index.tsx
var import_react12 = require("react");
var import_jsx_runtime38 = require("react/jsx-runtime");
var SearchInput = ({
  onValueChanged,
  onChange,
  placeholder = "Searching...",
  onKeyDown,
  value,
  className,
  defaultValue
}) => {
  const [keyword, setKeyword] = (0, import_react12.useState)();
  return /* @__PURE__ */ (0, import_jsx_runtime38.jsx)(
    TextInput_default,
    {
      isSearchField: true,
      placeholder,
      value,
      defaultValue,
      onChange: (e) => {
        setKeyword(e.target.value);
        onChange && onChange(e);
      },
      onKeyDown: (e) => {
        if (e.key === "Enter") {
          onValueChanged && onValueChanged(keyword != null ? keyword : "");
        }
        onKeyDown && onKeyDown(e);
      },
      className
    }
  );
};

// src/components/DataTable/DataTable/index.tsx
var import_jsx_runtime39 = require("react/jsx-runtime");
var DataTable = (props) => {
  const {
    title,
    data: data3,
    columns,
    total,
    actionButtons,
    paginationText,
    pageCount,
    containerClasses = "",
    sorting,
    setSorting,
    footer,
    getRowCanExpand,
    getSubRows,
    expandComponent,
    expandInSameTable = false,
    tableFixed,
    noHeading,
    options,
    copyTable = false,
    copyTableName,
    tableWrapperClass,
    globalFilterOption,
    paginateOption
  } = props;
  const tableOptions = __spreadProps(__spreadValues(__spreadValues(__spreadValues({
    data: data3,
    columns,
    pageCount: pageCount || 1,
    getRowCanExpand,
    getSubRows,
    state: __spreadValues(__spreadValues({
      sorting
    }, (globalFilterOption == null ? void 0 : globalFilterOption.manualFiltering) && globalFilterOption.state), (paginateOption == null ? void 0 : paginateOption.manualPagination) && paginateOption.state),
    onSortingChange: setSorting,
    getCoreRowModel: (0, import_react_table4.getCoreRowModel)(),
    getSortedRowModel: (0, import_react_table4.getSortedRowModel)(),
    getExpandedRowModel: (0, import_react_table4.getExpandedRowModel)(),
    manualPagination: paginateOption == null ? void 0 : paginateOption.manualPagination,
    manualFiltering: globalFilterOption == null ? void 0 : globalFilterOption.manualFiltering,
    enableFilters: !!globalFilterOption,
    globalFilterFn: "includesString",
    initialState: __spreadValues({}, (globalFilterOption == null ? void 0 : globalFilterOption.manualFiltering) && globalFilterOption.initialState)
  }, (globalFilterOption == null ? void 0 : globalFilterOption.manualFiltering) === true && {
    onGlobalFilterChange: globalFilterOption == null ? void 0 : globalFilterOption.onGlobalFilterChanged
  }), (paginateOption == null ? void 0 : paginateOption.manualPagination) === true && {
    onPaginationChange: paginateOption == null ? void 0 : paginateOption.setPagination
  }), options), {
    debugTable: false
  });
  if ((paginateOption == null ? void 0 : paginateOption.manualPagination) === false || (globalFilterOption == null ? void 0 : globalFilterOption.manualFiltering) === false) {
    console.log("rended");
    tableOptions.getPaginationRowModel = (0, import_react_table4.getPaginationRowModel)();
    tableOptions.getFilteredRowModel = (0, import_react_table4.getFilteredRowModel)();
  }
  const filterEnabled = (0, import_react13.useMemo)(
    () => !!globalFilterOption,
    [globalFilterOption]
  );
  console.log(tableOptions);
  const table = (0, import_react_table4.useReactTable)(tableOptions);
  const tableRef = (0, import_react13.useRef)(null);
  (0, import_react13.useEffect)(() => {
    if (getSubRows && getRowCanExpand && table.getCanSomeRowsExpand()) {
      table.toggleAllRowsExpanded();
    }
  }, []);
  const onClickCopy = () => __async(void 0, null, function* () {
    const res = yield copyToClipboard(tableRef);
    res && (0, import_react_toastify.toast)("copied");
  });
  return /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)(import_jsx_runtime39.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)("div", { className: `bg-white ${containerClasses ? "" : ""}`, children: [
      !noHeading && /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)(
        "div",
        {
          className: "pb-3 md:pb-5 px-2 md:px-4 flex flex-col\n            md:flex-row gap-2 justify-between border-b border-gray-200",
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime39.jsx)("div", { className: "flex flex-inline", children: title && /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)(import_jsx_runtime39.Fragment, { children: [
              /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
                Typography_default,
                {
                  varient: "h3" /* H3 */,
                  className: "text-gray-900 text-lg",
                  children: title
                }
              ),
              (total || total === 0) && /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(Badge_default, { text: total, className: "ml-2" })
            ] }) }),
            /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)("div", { className: "flex gap-2", children: [
              filterEnabled && /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
                SearchInput,
                {
                  defaultValue: table.getState().globalFilter,
                  onValueChanged: (filterString) => {
                    table.setGlobalFilter(filterString);
                  }
                }
              ),
              actionButtons,
              copyTable && document.queryCommandSupported("copy") && /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(Button_default, { variant: "sm" /* SM */, onClick: onClickCopy, children: copyTableName })
            ] })
          ]
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime39.jsx)("div", { className: `${tableWrapperClass}`, children: /* @__PURE__ */ (0, import_jsx_runtime39.jsxs)(
        "table",
        {
          ref: tableRef,
          className: `block xl:table ${tableFixed ? "table-fixed" : ""} table-flush w-full`,
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(TableHeader_default, { table }),
            /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
              TableBody_default,
              {
                table,
                expandComponent,
                expandInSameTable
              }
            ),
            footer && /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(TableFooter_default, { table })
          ]
        }
      ) }),
      paginateOption && /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
        TablePagination_default,
        {
          total: total || 0,
          table,
          pagination: table.getState().pagination,
          paginationText
        }
      )
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(import_react_toastify.ToastContainer, {})
  ] });
};
var DataTable_default = DataTable;

// src/components/DataTable/TableHeadCell/index.tsx
var import_react14 = __toESM(require("react"));
var import_jsx_runtime40 = require("react/jsx-runtime");
var TableHeadCell = import_react14.default.forwardRef(
  (_a, ref) => {
    var _b = _a, { value, className, children } = _b, otherProps = __objRest(_b, ["value", "className", "children"]);
    return /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(
      "th",
      __spreadProps(__spreadValues({
        ref,
        className: `border border-solid border-gray-300 px-3 md:px-4 py-2 text-gray-500 ${className}`
      }, otherProps), {
        children: children || value
      })
    );
  }
);
var TableHeadCell_default = TableHeadCell;

// src/components/hook-form/FormProvider/index.tsx
var import_react_hook_form = require("react-hook-form");
var import_jsx_runtime41 = require("react/jsx-runtime");
function FormProvider(_a) {
  var _b = _a, {
    children,
    onSubmit,
    methods
  } = _b, otherProps = __objRest(_b, [
    "children",
    "onSubmit",
    "methods"
  ]);
  return /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(import_react_hook_form.FormProvider, __spreadProps(__spreadValues({}, methods), { children: /* @__PURE__ */ (0, import_jsx_runtime41.jsx)("form", __spreadProps(__spreadValues({}, otherProps), { onSubmit, children })) }));
}

// src/components/hook-form/RHFCheckbox/index.tsx
var import_react_hook_form2 = require("react-hook-form");
var import_jsx_runtime42 = require("react/jsx-runtime");
function RHFTextField(_a) {
  var _b = _a, { name } = _b, other = __objRest(_b, ["name"]);
  const { control } = (0, import_react_hook_form2.useFormContext)();
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
    import_react_hook_form2.Controller,
    {
      name,
      control,
      render: ({ field, fieldState: { error } }) => /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(CheckBox_default, __spreadValues(__spreadProps(__spreadValues({}, field), { error, checked: field.value }), other))
    }
  );
}

// src/components/hook-form/RHFTextArea/index.tsx
var import_react_hook_form3 = require("react-hook-form");
var import_jsx_runtime43 = require("react/jsx-runtime");
function RHFTextArea(_a) {
  var _b = _a, {
    name,
    readOnly,
    rows = 3
  } = _b, other = __objRest(_b, [
    "name",
    "readOnly",
    "rows"
  ]);
  const { control, register } = (0, import_react_hook_form3.useFormContext)();
  return /* @__PURE__ */ (0, import_jsx_runtime43.jsx)(
    import_react_hook_form3.Controller,
    {
      name,
      control,
      render: ({ field, fieldState: { error } }) => /* @__PURE__ */ (0, import_jsx_runtime43.jsx)(
        TextArea_default,
        __spreadProps(__spreadValues(__spreadProps(__spreadValues({
          readOnly,
          rows
        }, field), {
          error
        }), other), {
          ref: register(name).ref
        })
      )
    }
  );
}

// src/components/hook-form/RHFTextField/index.tsx
var import_react_hook_form4 = require("react-hook-form");
var import_jsx_runtime44 = require("react/jsx-runtime");
function RHFTextField2(_a) {
  var _b = _a, {
    name,
    readOnly = false,
    helperText,
    hintText,
    isSearchField
  } = _b, other = __objRest(_b, [
    "name",
    "readOnly",
    "helperText",
    "hintText",
    "isSearchField"
  ]);
  const { control } = (0, import_react_hook_form4.useFormContext)();
  return /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(
    import_react_hook_form4.Controller,
    {
      name,
      control,
      render: ({ field, fieldState: { error } }) => /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(
        TextInput_default,
        __spreadValues(__spreadProps(__spreadValues({
          readOnly
        }, field), {
          error,
          isSearchField,
          hintText,
          helperText: error == null ? void 0 : error.message
        }), other)
      )
    }
  );
}

// src/components/hook-form/RHFSelectField/index.tsx
var import_react_hook_form5 = require("react-hook-form");
var import_jsx_runtime45 = require("react/jsx-runtime");
function RHFSelectField(_a) {
  var _b = _a, {
    name,
    options,
    disabled = false,
    variant
  } = _b, other = __objRest(_b, [
    "name",
    "options",
    "disabled",
    "variant"
  ]);
  const { control } = (0, import_react_hook_form5.useFormContext)();
  return /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(
    import_react_hook_form5.Controller,
    {
      name,
      control,
      render: ({ field, fieldState: { error } }) => /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(
        SelectInput_default,
        __spreadValues(__spreadProps(__spreadValues({
          disabled,
          options
        }, field), {
          error,
          variant
        }), other)
      )
    }
  );
}

// src/components/hook-form/RHFNumericField/index.tsx
var import_react_hook_form6 = require("react-hook-form");
var import_jsx_runtime46 = require("react/jsx-runtime");
var accessKeys = [
  "Backspace",
  "Delete",
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "ArrowDown",
  ".",
  ",",
  "Tab",
  "-"
];
function RHFNumericField(_a) {
  var _b = _a, {
    name,
    readOnly = false,
    type = "number"
  } = _b, other = __objRest(_b, [
    "name",
    "readOnly",
    "type"
  ]);
  const { control } = (0, import_react_hook_form6.useFormContext)();
  const handleKeyUp = (e) => {
    if (!/^\d+$/.test(e.key) && !accessKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  return /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(
    import_react_hook_form6.Controller,
    {
      name,
      control,
      render: ({ field, fieldState: { error } }) => /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(
        TextInput_default,
        __spreadValues(__spreadProps(__spreadValues({
          readOnly,
          onKeyDown: handleKeyUp,
          type
        }, field), {
          error,
          helperText: error == null ? void 0 : error.message,
          onWheel: (e) => e.currentTarget.blur()
        }), other)
      )
    }
  );
}

// src/components/hook-form/index.ts
var import_react_hook_form7 = require("react-hook-form");
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Badge,
  BarChart,
  Box,
  BreadCrumb,
  Button,
  CheckBox,
  DataTable,
  Drawer,
  EChart,
  FileInput,
  FormInputGroup,
  FormProvider,
  GaugeChart,
  GoogleButton,
  IconButton,
  Label,
  LabelChart,
  LineChart,
  Logo,
  LogoLoader,
  MicrosoftButton,
  OverlayLoader,
  PageHeading,
  PieChart,
  ProviderController,
  RHFCheckbox,
  RHFNumericField,
  RHFSelectField,
  RHFTextArea,
  RHFTextField,
  RadioInput,
  ReactSelect,
  RowLoader,
  SelectInput,
  Spinner,
  TableBody,
  TableBodyCell,
  TableFooter,
  TableHeadCell,
  TableHeader,
  TablePaginateCell,
  TablePagination,
  TextArea,
  TextInput,
  Tooltip,
  Typography,
  useProviderFormContext
});
