import {
  Cog8ToothIcon,
  ChartBarSquareIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
  HomeIcon,
  UsersIcon,
  AdjustmentsHorizontalIcon,
  ClipboardDocumentListIcon,
  PuzzlePieceIcon,
  DocumentTextIcon,
  ArrowTrendingUpIcon,
  BookOpenIcon,
  WindowIcon,
} from '@heroicons/react/24/outline';
import {ROLES_ACCESS} from '_constant/roles';
import {TSideBarItem} from '_types/types';

export const SIDE_BAR_ITEMS: TSideBarItem[] = [
  {
    id: 'main.title',
    type: 'Title',
    title: 'sidebar.main.title', // translation.json
    icon: HomeIcon,
    to: '/',
    end: true,
    access: [
      ...(ROLES_ACCESS.DASHBOARD?.view || []),
      ...(ROLES_ACCESS.XMATRIX.view || []),
      ...(ROLES_ACCESS.PROJECT_OVERVIEW.view || []),
    ],
  },
  {
    id: 'main.overview',
    type: 'Link',
    title: 'sidebar.main.overview',
    icon: HomeIcon,
    to: '/',
    end: true,
    access: ROLES_ACCESS.DASHBOARD.view,
  },
  {
    id: 'main.xmatrix',
    type: 'Link',
    title: 'sidebar.main.xmatrix',
    icon: Squares2X2Icon,
    to: '/xmatrix',
    access: ROLES_ACCESS.XMATRIX.view,
  },
  {
    id: 'main.project_overview',
    type: 'Link',
    title: 'sidebar.main.project_overview',
    icon: Square3Stack3DIcon,
    to: '/project-overview',
    access: ROLES_ACCESS.PROJECT_OVERVIEW.view,
  },
  {
    id: 'main.my_tasks',
    type: 'Link',
    title: 'sidebar.dashboard.my_tasks',
    icon: ClipboardDocumentListIcon,
    to: '/project-management',
    access: ROLES_ACCESS.PROJECT_MANAGEMENT.view,
  },
  {
    id: 'dashboard.title',
    type: 'Title',
    title: 'sidebar.dashboard.title',
    icon: ChartBarSquareIcon,
    to: '/',
    access: [
      ...(ROLES_ACCESS.STRATEGY_BUILDING.view || []),
      ...(ROLES_ACCESS.EXECUTION_MANAGEMENT.view || []),
      ...(ROLES_ACCESS.ANALYTICS.view || []),
      ...(ROLES_ACCESS.ALL_TASKS.view || []),
    ],
  },
  {
    id: 'dashboard.strategy_building',
    type: 'Link',
    title: 'sidebar.dashboard.strategy_building',
    icon: PuzzlePieceIcon,
    to: '/strategy-building',
    access: ROLES_ACCESS.STRATEGY_BUILDING.view,
    subMenus: [
      {
        id: 'dashboard.strategy_building.alignment_report',
        type: 'Link',
        title: 'sidebar.dashboard.alignment_report',
        icon: PuzzlePieceIcon,
        to: '/strategy-building/alignment-report',
        access: ROLES_ACCESS.STRATEGY_BUILDING.view,
      },
      {
        id: 'dashboard.strategy_building.tag_group_report',
        type: 'Link',
        title: 'sidebar.dashboard.tag_group_report',
        icon: PuzzlePieceIcon,
        to: '/strategy-building/tag-group-report',
        access: ROLES_ACCESS.STRATEGY_BUILDING.view,
      },
      {
        id: 'dashboard.strategy_building.comparison_report',
        type: 'Link',
        title: 'sidebar.dashboard.comparison_report',
        icon: PuzzlePieceIcon,
        to: '/strategy-building/comparison-report',
        access: ROLES_ACCESS.STRATEGY_BUILDING.view,
      },
      {
        id: 'dashboard.strategy_building.alignment_builder',
        type: 'Link',
        title: 'sidebar.dashboard.alignment_builder',
        icon: PuzzlePieceIcon,
        to: '/strategy-building/alignment-builder',
        access: ROLES_ACCESS.STRATEGY_BUILDING.view,
      },
      {
        id: 'dashboard.strategy_building.objective_management',
        type: 'Link',
        title: 'sidebar.dashboard.objective_management',
        icon: PuzzlePieceIcon,
        to: '/strategy-building/objective-management',
        access: ROLES_ACCESS.STRATEGY_BUILDING.view,
      },
      {
        id: 'dashboard.strategy_building.kpi_management',
        type: 'Link',
        title: 'sidebar.dashboard.kpi_management',
        icon: PuzzlePieceIcon,
        to: '/strategy-building/kpi-management',
        access: ROLES_ACCESS.STRATEGY_BUILDING.view,
      },
    ],
  },
  {
    id: 'dashboard.exec_management',
    type: 'Link',
    title: 'sidebar.dashboard.exec_management',
    icon: ChartBarSquareIcon,
    to: '/execution-management',
    access: ROLES_ACCESS.EXECUTION_MANAGEMENT.view,
    subMenus: [
      {
        id: 'dashboard.exec_management.strategy_progress',
        type: 'Link',
        title: 'sidebar.dashboard.strategy_progress',
        icon: PuzzlePieceIcon,
        to: '/execution-management/strategy-progress',
        access: ROLES_ACCESS.EXECUTION_MANAGEMENT.view,
      },
      {
        id: 'dashboard.exec_management.kpi_report',
        type: 'Link',
        title: 'sidebar.dashboard.kpi_report',
        icon: PuzzlePieceIcon,
        to: '/execution-management/kpi-report',
        access: ROLES_ACCESS.EXECUTION_MANAGEMENT.view,
      },
      {
        id: 'dashboard.exec_management.monthly_kpi_report',
        type: 'Link',
        title: 'sidebar.dashboard.monthly_kpi_report',
        icon: PuzzlePieceIcon,
        to: '/execution-management/monthly-kpi-report',
        access: ROLES_ACCESS.EXECUTION_MANAGEMENT.view,
      },
      {
        id: 'dashboard.exec_management.comparison_report',
        type: 'Link',
        title: 'sidebar.dashboard.comparison_report',
        icon: PuzzlePieceIcon,
        to: '/execution-management/comparison-report',
        access: ROLES_ACCESS.EXECUTION_MANAGEMENT.view,
      },
      {
        id: 'dashboard.exec_management.project_overview',
        type: 'Link',
        title: 'sidebar.dashboard.project_overview',
        icon: PuzzlePieceIcon,
        to: '/execution-management/project-overview',
        access: ROLES_ACCESS.EXECUTION_MANAGEMENT.view,
      },
      {
        id: 'dashboard.exec_management.progress_report',
        type: 'Link',
        title: 'sidebar.dashboard.progress_report',
        icon: PuzzlePieceIcon,
        to: '/execution-management/progress-report',
        access: ROLES_ACCESS.EXECUTION_MANAGEMENT.view,
      },
    ],
  },
  {
    id: 'dashboard.analytics',
    type: 'Link',
    title: 'sidebar.dashboard.analytics',
    icon: ArrowTrendingUpIcon,
    to: '/analytics',
    access: ROLES_ACCESS.ANALYTICS.view,
    subMenus: [
      {
        id: 'dashboard.analytics.portfolio_report',
        type: 'Link',
        title: 'sidebar.dashboard.portfolio_report',
        icon: PuzzlePieceIcon,
        to: '/analytics/portfolio-report',
        access: ROLES_ACCESS.ANALYTICS.view,
      },
      {
        id: 'dashboard.analytics.benefit_report',
        type: 'Link',
        title: 'sidebar.dashboard.benefit_report',
        icon: PuzzlePieceIcon,
        to: '/analytics/benefit-report',
        access: ROLES_ACCESS.ANALYTICS.view,
      },
      {
        id: 'dashboard.analytics.project_involvement',
        type: 'Link',
        title: 'sidebar.dashboard.project_involvement',
        icon: PuzzlePieceIcon,
        to: '/analytics/project-involvement',
        access: ROLES_ACCESS.ANALYTICS.view,
      },

      {
        id: 'dashboard.analytics.project_resources',
        type: 'Link',
        title: 'sidebar.dashboard.project_resources',
        icon: PuzzlePieceIcon,
        to: '/analytics/project-resources',
        access: ROLES_ACCESS.ANALYTICS.view,
      },
      {
        id: 'dashboard.analytics.alignment_overview',
        type: 'Link',
        title: 'sidebar.dashboard.alignment_overview',
        icon: PuzzlePieceIcon,
        to: '/analytics/alignment-overview',
        access: ROLES_ACCESS.ANALYTICS.view,
      },
      {
        id: 'dashboard.analytics.project_followup',
        type: 'Link',
        title: 'sidebar.dashboard.project_followup',
        icon: PuzzlePieceIcon,
        to: '/analytics/project-follow-up',
        access: ROLES_ACCESS.ANALYTICS.view,
      },
      {
        id: 'dashboard.analytics.risk_matrix',
        type: 'Link',
        title: 'sidebar.dashboard.risk_matrix',
        icon: PuzzlePieceIcon,
        to: '/analytics/risk-matrix',
        access: ROLES_ACCESS.ANALYTICS.view,
      },
      {
        id: 'dashboard.analytics.x_matrix_playback',
        type: 'Link',
        title: 'sidebar.dashboard.x_matrix_playback',
        icon: PuzzlePieceIcon,
        to: '/analytics/x-matrix-playback',
        access: ROLES_ACCESS.ANALYTICS.view,
      },
    ],
  },
  {
    id: 'dashboard.all_tasks',
    type: 'Link',
    title: 'sidebar.dashboard.all_tasks',
    icon: DocumentTextIcon,
    to: '/all-tasks',
    access: ROLES_ACCESS.ALL_TASKS.view,
  },
  {
    id: 'others.title',
    type: 'Title',
    title: 'sidebar.others.title',
    icon: ChartBarSquareIcon,
    to: '/reports',
  },
  {
    id: 'others.users',
    type: 'Link',
    title: 'sidebar.others.users',
    icon: UsersIcon,
    to: '/users',
    access: ROLES_ACCESS.USER.view,
  },
  {
    id: 'others.settings',
    type: 'Link',
    title: 'sidebar.others.settings',
    icon: Cog8ToothIcon,
    to: '/settings',
    access: ROLES_ACCESS.SETTING.view,
  },
  {
    id: 'others.integrations',
    type: 'Link',
    title: 'sidebar.others.integrations',
    icon: AdjustmentsHorizontalIcon,
    to: '/integrations',
    access: ROLES_ACCESS.INTEGRATIONS.view,
  },
  {
    id: 'others.release_info',
    type: 'Link',
    title: 'sidebar.others.release_info',
    icon: WindowIcon,
    to: '/release-info',
    comingSoon: true,
  },
  {
    id: 'others.user_manuals',
    type: 'Link',
    title: 'sidebar.others.user_manuals',
    icon: BookOpenIcon,
    to: '/user-manuals',
    comingSoon: true,
  },
];
