import {TDropdownOption} from 'ui-library/_types';
import {EColorVariant} from 'ui-library/_enum';
import {
  ERROR_COLOR_CODE,
  GRAY_COLOR_CODE,
  PRIMARY_COLOR_CODE,
  WARNING_COLOR_CODE,
} from 'ui-library/_constant';
import {TTag, TTagGroup} from '_types/types';
import moment from 'moment';

export const classNames = (...classes: any[]) =>
  classes.filter(Boolean).join(' ');

export const convertTagGroupToOption = (
  groups: any[],
  empty = false
): TDropdownOption[] => {
  const data: any[] = [];

  if (empty) {
    data.push({label: 'Choose Tag Group', value: ''});
  }
  groups.map(unit => {
    data.push({
      label: unit.group_name,
      value: unit.id,
    });
  });

  return data;
};

export const orgUsersToOption = (orgUsers: any[], empty = false): any => {
  const data: any[] = [];

  if (empty) {
    data.push({label: 'Select', value: ''});
  }
  orgUsers?.map((item: any) => {
    data.push({
      label: `${item.full_name}`,
      value: item.id,
    });
  });

  return data;
};

export const convertTagToOption = (
  units: any[],
  empty = false
): TDropdownOption[] => {
  const data: any[] = [];

  if (empty) {
    data.push({label: 'Choose Tag', value: ''});
  }
  units.map(unit => {
    data.push({
      label: unit.tag_name,
      value: unit.id,
    });
  });

  return data;
};

export const renderColor = (color: string) => {
  switch (color) {
    case 'G':
      return 'text-primary-700';
    case 'R':
      return 'text-error-500';
    case 'Y':
      return 'text-warning-400';
    default:
      return 'text-primary-700';
  }
};

export const renderAccentColor = (color: string) => {
  switch (color) {
    case 'G':
      return 'accent-primary-700';
    case 'R':
      return 'accent-error-500';
    case 'Y':
      return 'accent-warning-300';
    default:
      return 'accent-primary-700';
  }
};

export const renderKpiColor = (status: string) => {
  switch (status) {
    case 'S':
      return 'text-warning-400';
    case 'U':
      return 'text-primary-700';
    case 'D':
      return 'text-error-500';
    default:
      return 'text-primary-700';
  }
};

export const renderKpiBGColor = (status: string) => {
  switch (status) {
    case 'S':
      return 'bg-warning-300';
    case 'U':
    case 'A':
      return 'bg-primary-300';
    case 'D':
      return 'bg-error-300';
    default:
      return '';
  }
};

export const renderGraphColor = (color: string) => {
  switch (color) {
    case 'G':
      return EColorVariant.PRIMARY;
    case 'R':
      return EColorVariant.ERROR;
    case 'Y':
      return EColorVariant.WARNING;
    default:
      return EColorVariant.PRIMARY;
  }
};

export const renderColorCode = (color: string) => {
  switch (color) {
    case 'G':
      return PRIMARY_COLOR_CODE[700];
    case 'R':
      return ERROR_COLOR_CODE[500];
    case 'Y':
      return WARNING_COLOR_CODE[500];
    default:
      return GRAY_COLOR_CODE[200];
  }
};

export const getKpiColorStatus = (color?: string | null) => {
  switch (color) {
    case 'S':
      return 'constants.kpi.status.stabile';
    case 'U':
      return 'constants.kpi.status.improving';
    case 'D':
      return 'constants.kpi.status.worsening';
    default:
      return '';
  }
};

export const pad = (d: number) => (d < 10 ? '0' + d.toString() : d.toString());

export const getFormsTagGroups = (groups?: (TTagGroup | undefined)[]) =>
  groups
    ?.filter(tagGroup => tagGroup?.id)
    .map(tagGroup => ({
      id: tagGroup?.id,
      tag_ids: tagGroup?.correlation_tags?.map(tag => tag?.id).sort() || [],
    }));

export const getFormsTagsForCorelation = (
  groups: TTagGroup[],
  tags: Partial<TTag>[]
) =>
  groups
    ?.filter(tagGroup => tagGroup?.id)
    .map(tagGroup => ({
      id: tagGroup?.id,
      tag_ids: tags.map(tag => tag?.id) || [],
    }));

export const getMonthOptions = (format = 'MM') =>
  moment.months().map(item => ({
    label: item,
    value: moment().month(item).format(format),
  }));

export const getYearOptions = () =>
  getYearsArray().map(item => ({
    label: item,
    value: item,
  }));

export const getYearsArray = (prev = 5, next = 5) => {
  const current = new Date().getFullYear();
  const years = [];

  for (let i = prev; i >= 0; i--) {
    years.push(current - i);
  }
  for (let i = 1; i <= next; i++) {
    years.push(current + i);
  }

  return years;
};

export function validateEmail(email: string) {
  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}

export const downloadCsv = (csv: string, filename: string) => {
  const csvFile = new Blob([csv], {type: 'text/csv'});
  const downloadLink = document.createElement('a');

  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export const getAvarageProjectReadiness = (
  projects?: {project_readiness: number}[],
  returnAsNumber?: boolean
) => {
  if (projects && projects.length) {
    const totalProjectReadiness = projects.reduce(
      (total: number, item: {project_readiness: number}) =>
        total + item.project_readiness || 0,
      0
    );

    const avarage = totalProjectReadiness / projects.length;

    if (returnAsNumber) {
      return avarage;
    }

    return avarage.toLocaleString('fi-FI', {
      maximumFractionDigits: 2,
    });
  }

  if (returnAsNumber) {
    return 0;
  }

  return '0';
};

export const formatNumberBasedOnLocale = (
  numberToFormat: number | string | null | undefined
) => {
  if (numberToFormat === null || numberToFormat === undefined) {
    return numberToFormat;
  }

  let newNumber;

  if (typeof numberToFormat === 'number') {
    newNumber = new Intl.NumberFormat().format(numberToFormat);
  } else {
    newNumber = new Intl.NumberFormat().format(Number(numberToFormat));
  }

  return newNumber;
};

export const getRedirectUrl = (state: string | null | undefined) => {
  if (!state) {
    return '/dashboard';
  }
  try {
    const redirectUrl = atob(state);

    const url = new URL(redirectUrl);

    if (url.host === window.location.host) {
      return `${url.pathname}${url.search}`;
    }
  } catch (e) {
    console.error(e);
  }

  return '/dashboard';
};

export const stripTrailingSlash = (url: string) => url.replace(/\/$/, '');

export const getNewOrganizationHref = (
  organizationSubdomain?: string | null
) => {
  const currentOrigin = window.location.origin;

  const url = new URL(currentOrigin);
  const hosts = url.host.split('.');

  if (hosts.length === 4) {
    if (!organizationSubdomain) {
      url.host = hosts.slice(1, 4).join('.');
    } else {
      hosts[0] = organizationSubdomain;
      url.host = hosts.join('.');
    }
  } else if (organizationSubdomain) {
    url.host = [organizationSubdomain, ...hosts].join('.');
  }

  return stripTrailingSlash(url.href);
};

export const getNewOrganizationSSORedirectUrl = (
  organizationSubdomain?: string | null
) => {
  const href = getNewOrganizationHref(organizationSubdomain);

  return `${href}/auth/callback`;
};

function getWeeksArray(startDate: Date, endDate: Date) {
  const weeksArray = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const week = {
      year: currentDate.getFullYear(),
      weekNumber: getWeekNumber(currentDate),
    };

    weeksArray.push(week);
    currentDate.setDate(currentDate.getDate() + 7); // Move to next week
  }

  return weeksArray;
}

function getWeekNumber(date: any) {
  const oneJan: any = new Date(date.getFullYear(), 0, 1);
  const millisecondsInDay = 86400000;

  return Math.ceil(
    ((date - oneJan) / millisecondsInDay + oneJan.getDay() + 1) / 7
  );
}

export const getWeeks = (monthCount: number) => {
  const currentDate = new Date();
  const startMonthDate = new Date(currentDate);

  startMonthDate.setMonth(startMonthDate.getMonth() - monthCount);

  const weeksArray = getWeeksArray(startMonthDate, currentDate);

  return weeksArray;
};

export const getWeekInfo = (date: string) => {
  const currentDate = new Date(date);

  return {
    year: currentDate.getFullYear(),
    weekNumber: getWeekNumber(currentDate),
  };
};
