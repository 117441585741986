import {EKPI_TYPE} from '_enum';

export const KPI_TYPES = [
  {label: 'Irregular KPI', value: EKPI_TYPE.IRREGULAR_KPI},
  {
    label: 'Cumulative KPI',
    value: EKPI_TYPE.CUMULATIVE_KPI,
  },
];
export const KPI_STATUS = [
  {
    label: 'constants.kpi.status.on_target',
    value: 'U',
  },
  {label: 'constants.kpi.status.near_miss', value: 'S'},
  {label: 'constants.kpi.status.miss', value: 'D'},
];
export const IRREGULAR_KPI_STATUS = [
  {label: 'constants.kpi.status.above_target', value: 'A'}, // translation.json
  {
    label: 'constants.kpi.status.improving',
    value: 'U',
  },
  {label: 'constants.kpi.status.worsening', value: 'D'},
  {label: 'constants.kpi.status.stabile', value: 'S'},
];

export const HISTORY_VALUE_FIELDS = {
  cumulative: [
    'cumulative_kpi_numerical_forecast_value',
    'cumulative_kpi_numerical_goal_value',
    'cumulative_kpi_numerical_last_value',
    'kpi_status',
  ],
  regular: [
    'kpi_numerical_forecast_value',
    'kpi_numerical_goal_value',
    'kpi_numerical_last_value',
    'kpi_status',
  ],
};
