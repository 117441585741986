export enum ProjectManagementTabEnum {
  PM_REPORTS = 0,
  PROJECT_OVERVIEW = 1,
  MONTHLY_KPIS = 2,
  OBJECTIVE_STATUS = 3,
  BACKLOG = 4,
  ACTIVE_PROJECT = 5,
}

export enum ReportIdTabEnum {
  BenefitReport = 'benefit-report',
  ProjectInvolvement = 'project-involvement',
  PortfolioReport = 'portfolio-report',
  ProjectResource = 'project-resources',
  AlignmentOverview = 'alignment-overview',
  ProjectFollowUp = 'project-follow-up',
  RiskXMatrix = 'risk-matrix',
  XMatrixPlayback = 'x-matrix-playback',
}
