import NavItem from './NavItem';
import NavTitle from './NavTitle';
import {SIDE_BAR_ITEMS} from '_constant/sidebar';
import {RootState} from 'redux/rootReducer';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {TSideBarItem} from '_types/types';
import UserInitials from '../../common/UserInitials';
import {getInitials} from '../../utils/user-utils';
import {ArrowRightOnRectangleIcon} from '@heroicons/react/24/solid';
import {dispatch} from '../../redux/store';
import {logoutUser} from '../../redux/reducers/auth';

const Sidebar = () => {
  const {auth} = useSelector((state: RootState) => state);

  const {t} = useTranslation();

  const onLogoutClick = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
    } catch (err) {
      /* empty */
    }
  };

  const PADDING_X = 4;

  return (
    <div className="h-screen pt-[3px] bg-white border-r relative w-full flex flex-col overflow-auto overflow-y-hidden">
      <div className={`h-14 mt-3  flex items-center px-${PADDING_X}`}>
        <div className="inline-flex items-center">
          <h1 className="text-[24px] duration-300 font-semibold text-gray-600">
            {t('app_name')}
          </h1>
          <img
            className="w-auto h-8 ml-1"
            src="/img/amplon-logo.png"
            alt={t('app_name')}
          />
        </div>
      </div>
      <nav
        className={` h-[calc(100vh-10rem)] overflow-y-scroll px-${PADDING_X}`}
      >
        <ul className="space-y-2">
          {SIDE_BAR_ITEMS.filter(
            (item: TSideBarItem) =>
              item.access?.includes(auth.user?.role) ||
              item.access === undefined
          ).map((item, index) =>
            item.type === 'Title' ? (
              <NavTitle
                to={item.to}
                key={index}
                text={item.title}
                IconElement={item.icon}
                end={item.end}
              />
            ) : (
              <NavItem
                to={item.to}
                key={index}
                itemId={item.id}
                text={item.title}
                IconElement={item.icon}
                end={item.end}
                comingSoon={item.comingSoon}
                subMenus={item.subMenus}
              />
            )
          )}
        </ul>
      </nav>

      <div className={`px-${PADDING_X} justify-end flex flex-col grow`}>
        <div className="mt-3 py-4 gap-2 flex w-full border-t border-gray-300 ">
          <UserInitials
            initials={getInitials(auth.user?.full_name)}
            size="sm"
          />
          <div className="grow">
            <h6 className="text-gray-700 mb-0 text-sm font-semibold">
              {auth.user?.full_name}
            </h6>
            <p className="text-xs font-normal text-gray-700 break-all">
              {auth.user?.email}
            </p>
          </div>
          <button
            onClick={onLogoutClick}
            title={t('header.log_out')}
            className="cursor-pointer hover:bg-gray-50 p-2 text-gray-700 font-bold"
          >
            <ArrowRightOnRectangleIcon className="w-5 h-5 text-gray-700 group-hover:text-primary-700" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
