import {IXMatrixTextSettings} from '../interfaces/organization';

export const XMATRIX_CENTER_TEXT_DEFAULT: IXMatrixTextSettings = {
  LongTermObjectiveLabel: 'constants.categories.ltos', // translation.json
  AnnualObjectiveLabel: 'constants.categories.aos',
  ProjectLabel: 'constants.categories.projs',
  KPILabel: 'constants.categories.kpis',
  KPITargetCreationLimited: '',
  KPITargetCreationLimitYear: '',
  KPITargetCreationLimitMonth: '',
};

export const X_MATRIX_ROW_MIN_AMOUNTS = {
  long_term_objectives: 4,
  annual_objectives: 6,
  improvement_projects: 8,
  targets_and_kpis: 6,
  end_users: 6,
};

export const X_MATRIX_PLAYBACK_MONTHS_OPTIONS = [
  {
    label: '6 months',
    value: 6,
  },
  {
    label: '9 months',
    value: 9,
  },
  {
    label: '12 months',
    value: 12,
  },
  {
    label: '15 months',
    value: 15,
  },
  {
    label: '18 months',
    value: 18,
  },
  {
    label: '21 months',
    value: 21,
  },
  {
    label: '24 months',
    value: 24,
  },
];
