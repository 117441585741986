export enum EKPI_TYPE {
  IRREGULAR_KPI = 1,
  CUMULATIVE_KPI = 2,
  REGULAR_KPI = 3,
}

export enum KPI_TYPE {
  FIXED_PERCENTAGE_KPI = '1',
  ASCENDING_TARGET_KPI = '2',
  DESCENDING_TARGET_KPI = '3',
  RANGE_BOUND_KPI = '4',
}
