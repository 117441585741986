import {createContext} from 'react';
import {TSideBarItem} from '../../_types/types';

export const SidebarContext = createContext<{
  toggleItem: (id: string) => void;
  expandedItems: Set<string>;
  navItems: TSideBarItem[];
}>({
  navItems: [],
  toggleItem: (id: string) => {},
  expandedItems: new Set<string>(),
});
