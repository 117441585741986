import {RoleEnum} from '_enum';
import {TRole, TRoleAccess} from '_types/types';

export const ROLES: TRole[] = [
  {value: RoleEnum.ADMIN, label: 'constants.users.roles.admin'}, // translation.json
  {value: RoleEnum.Executive, label: 'constants.users.roles.executive'},
  {value: RoleEnum.PM, label: 'constants.users.roles.pm'},
  {value: RoleEnum.Other, label: 'constants.users.roles.viewer'},
  {value: RoleEnum.CONSULTANT, label: 'constants.users.roles.consultant'},
  {value: RoleEnum.PARTNER, label: 'constants.users.roles.partner'},
  {value: RoleEnum.NO_ACCESS, label: 'constants.users.roles.no_access'},
];

export const ROLES_ACCESS: TRoleAccess = {
  DASHBOARD: {
    view: [
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.Executive,
      RoleEnum.PM,
      RoleEnum.CONSULTANT,
      RoleEnum.Other,
    ],
  },
  ORGANIZATION: {
    view: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN],
    edit: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN],
    add: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN],
  },
  PROJECT_OVERVIEW: {
    view: [
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.PM,
      RoleEnum.CONSULTANT,
      RoleEnum.Other,
      RoleEnum.Executive,
    ],
  },
  STRATEGY_BUILDING: {
    view: [
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.Executive,
      RoleEnum.CONSULTANT,
    ],
  },
  EXECUTION_MANAGEMENT: {
    view: [
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.Executive,
      RoleEnum.CONSULTANT,
    ],
  },
  ANALYTICS: {
    view: [
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.Executive,
      RoleEnum.CONSULTANT,
    ],
  },
  PROJECT_MANAGEMENT: {
    view: [
      RoleEnum.PARTNER,
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.PM,
      RoleEnum.Executive,
    ],
  },
  ALL_TASKS: {
    view: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN, RoleEnum.Executive],
  },
  USER: {
    view: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN, RoleEnum.Executive],
    edit: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN, RoleEnum.Executive],
    upload: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN],
    unitAccessRight: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN],
    add: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN, RoleEnum.Executive],
  },
  TAG: {
    view: [
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.Executive,
      RoleEnum.PM,
    ],
  },
  KPI: {
    edit: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN, RoleEnum.Executive],
  },
  XMATRIX: {
    view: [
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.PM,
      RoleEnum.Executive,
      RoleEnum.CONSULTANT,
      RoleEnum.Other,
    ],
    edit: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN, RoleEnum.Executive],
  },
  LOG_BOOK: {
    add: [
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.Executive,
      RoleEnum.PM,
    ],
  },
  PROJECT_CHARTER: {
    view: [
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.Executive,
      RoleEnum.PM,
      RoleEnum.CONSULTANT,
      RoleEnum.Other,
      RoleEnum.NO_ACCESS,
    ],
    add: [
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.Executive,
      RoleEnum.PM,
    ],
    edit: [
      RoleEnum.GLOBAL_ADMIN,
      RoleEnum.ADMIN,
      RoleEnum.Executive,
      RoleEnum.PM,
    ],
  },
  SETTING: {
    view: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN],
  },
  INTEGRATIONS: {
    view: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN, RoleEnum.Executive],
    add: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN, RoleEnum.Executive],
  },
  ALERTS: {
    add: [RoleEnum.GLOBAL_ADMIN, RoleEnum.ADMIN, RoleEnum.Executive],
  },
};

export const ROLES_PRIORITY: Record<RoleEnum, number> = {
  [RoleEnum.GLOBAL_ADMIN]: 0,
  [RoleEnum.ADMIN]: 1,
  [RoleEnum.Executive]: 2,
  [RoleEnum.PM]: 3,
  [RoleEnum.Other]: 4,
  [RoleEnum.CONSULTANT]: 5,
  [RoleEnum.PARTNER]: 6,
  [RoleEnum.NO_ACCESS]: 7,
};
