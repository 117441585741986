import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {TSideBarItem} from '../../_types/types';
import {ChevronRightIcon} from '@heroicons/react/24/outline';
import {SidebarContext} from '../context/SidebarContext';

interface INavItemProps {
  itemId: string;
  text: string;
  active?: boolean;
  to: string;
  end?: boolean;
  IconElement?: React.ElementType;
  subMenus?: TSideBarItem[];
  depth?: number;
  className?: string;
  comingSoon?: boolean;
}

export default function NavItem({
  itemId,
  to,
  text,
  IconElement,
  end,
  subMenus,
  depth,
  className,
  comingSoon,
}: INavItemProps) {
  const {t} = useTranslation();
  const {expandedItems, toggleItem} = useContext(SidebarContext);
  const indentDepth = depth ?? 0;
  const activeRef = useRef<HTMLAnchorElement | null>(null); // Ref for the active menu item
  const contentRef = useRef<HTMLUListElement>(null);

  const isExpanded = useMemo(() => expandedItems.has(itemId), [expandedItems]);

  const clickEventHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      toggleItem(itemId);
    },
    [expandedItems, toggleItem, itemId]
  );

  useEffect(() => {
    // Scroll the active menu item into view only on the first load (refresh)
    if (activeRef.current) {
      activeRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [activeRef.current]);

  return (
    <>
      <li>
        <NavLink
          className={({isActive}) =>
            'group flex p-2 gap-x-2 items-center text-[14px] font-medium ' +
            'rounded-md hover:bg-primary-500 hover:bg-opacity-10 hover:text-primary-700 ' +
            (isActive
              ? 'bg-primary-500 bg-opacity-10 text-primary-700 group [&>button>svg]:text-primary-700 '
              : 'text-gray-700') +
            `${className}`
          }
          ref={el => {
            // Assign ref only to the active menu
            if (location.pathname === to && activeRef.current === null) {
              activeRef.current = el;
            }
          }}
          onClick={() => toggleItem(itemId)}
          end={end}
          to={to}
          style={{
            pointerEvents: comingSoon ? 'none' : undefined,
            paddingInlineStart: `${indentDepth < 1 ? 0.6 : indentDepth * 2}rem`,
          }}
        >
          {IconElement && (
            <IconElement className="w-4 h-4 block float-left text-gray-700 group-hover:text-primary-700 " />
          )}
          <span className="flex grow whitespace-nowrap duration-200 origin-left flex-nowrap overflow-hidden">
            {t(text)}
          </span>
          {comingSoon && (
            <span className="flex items-center gap-x-1 text-xs px-2 py-[2px]  text-warning-700 bg-warning-100  rounded-lg">
              <span className="h-[6px] w-[6px] inline-block bg-warning-700 rounded-full" />
              Coming...
            </span>
          )}
          {subMenus && (
            <button
              onClick={clickEventHandler}
              className="w-4 h-4 size-6 stroke-4 block float-right text-gray-700 group-hover:text-primary-700 group-bg-primary-700"
            >
              <ChevronRightIcon
                className={`
                w-4 h-4 size-5 stroke-[2px] transition-all duration-200 block float-right text-gray-700 group-hover:text-primary-700 ${
                  isExpanded && 'rotate-90'
                }`}
              />
            </button>
          )}
        </NavLink>
      </li>
      {subMenus && (
        <ul
          ref={contentRef}
          className="transition-all duration-300 space-y-2"
          style={{
            overflowY: 'hidden',
            height: !isExpanded ? 0 : 'auto',
            ...(!isExpanded && {marginTop: 0}),
            //`${contentRef.current?.scrollHeight || 0}px`,
          }}
        >
          {subMenus.map(item => (
            <NavItem
              key={item.id}
              text={item.title}
              subMenus={item.subMenus}
              to={item.to}
              itemId={item.id}
              depth={indentDepth + 1}
            />
          ))}
        </ul>
      )}
    </>
  );
}
