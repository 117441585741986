import Support from 'components/Support';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Outlet, useLocation} from 'react-router-dom';
import {RootState} from 'redux/rootReducer';
import {LogoLoader} from 'ui-library';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import {SidebarContext} from './context/SidebarContext';
import {SIDE_BAR_ITEMS} from '../_constant/sidebar';
import {TSideBarItem} from '../_types/types';

export default function AuthenticatedLayout() {
  const {orgUnits} = useSelector((state: RootState) => state);
  const [sidebarOpen, toggleSidebar] = useState<boolean>(true);
  const [mobileToggle, setMobileToggle] = useState<boolean>(false);
  const [expandedNavItems, setExpandedNavItems] = useState<Set<string>>(
    () => new Set<string>()
  );
  const location = useLocation();

  const findExpandedMenus = useCallback(
    (menuItems: TSideBarItem[], currentPath: string): string[] | null => {
      for (const item of menuItems) {
        if (item.to === currentPath) {
          return [item.id];
        }
        if (item.subMenus) {
          const childResult = findExpandedMenus(item.subMenus, currentPath);

          if (childResult) {
            return [item.id, ...childResult];
          }
        }
      }

      return null;
    },
    []
  );

  const toggleSideMenu = useCallback((id: string) => {
    setExpandedNavItems(prev => {
      const updated = new Set(prev);

      if (updated.has(id)) {
        updated.delete(id);
      } else {
        updated.add(id);
      }

      return updated;
    });
  }, []);

  useEffect(() => {
    console.log(location.pathname);
    const expandedMenus = findExpandedMenus(SIDE_BAR_ITEMS, location.pathname);

    setExpandedNavItems(new Set(expandedMenus ?? []));
  }, []);

  const SIDEBAR_WIDTH = 80;

  return (
    <>
      <div className="max-width-[1920px] h-screen flex flex-row overflow-hidden bg-[#F4F5F7]">
        <div
          className={`transform sidebar-basis w-[280px] duration-300
          overflow-hidden h-screen fixed z-20 top-0 left-0 -translate-x-[280px] ${
            sidebarOpen
              ? 'lg:translate-x-0 '
              : '-translate-x-[280px] duration-200 ' +
                `${mobileToggle && 'translate-x-0 lg:-translate-x-[280px] '}`
          }`}
        >
          <SidebarContext.Provider
            value={{
              navItems: SIDE_BAR_ITEMS,
              toggleItem: toggleSideMenu,
              expandedItems: expandedNavItems,
            }}
          >
            <Sidebar />
          </SidebarContext.Provider>
        </div>
        <div
          onClick={() => {
            toggleSidebar(!sidebarOpen);
            setMobileToggle(!mobileToggle);
          }}
          className={
            'top-0 left-0 h-screen w-full z-10 fixed bg-black bg-opacity-20 cursor-pointer ' +
            `${mobileToggle ? 'lg:hidden' : 'hidden'}`
          }
        />
        <div
          className={`flex flex-col flex-1 overflow-hidden duration-300 ${
            sidebarOpen ? 'lg:pl-[280px]' : 'pl-0'
          } `}
        >
          <Header
            toggleSidebar={() => {
              toggleSidebar(!sidebarOpen);
              setMobileToggle(!mobileToggle);
            }}
          />

          <main className="flex-1 overflow-x-hidden overflow-y-auto">
            <div className="lg:container-2xl p-2 md:p-6 m-auto">
              {orgUnits.loading ? <LogoLoader /> : <Outlet />}
            </div>
          </main>
        </div>
      </div>
      <Support />
    </>
  );
}
