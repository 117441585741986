export enum OrganizationSettingsType {
  PROJECT_TEXT_INFO_SETTING = 'PROJECT_TEXT_INFO_SETTING',
  X_MATRIX_LABELS = 'X_MATRIX_LABELS',
}

export enum XMatrixSettingsFieldEnum {
  ProjectLabel = 'ProjectLabel',
  KPILabel = 'KPILabel',
  LongTermObjectiveLabel = 'LongTermObjectiveLabel',
  AnnualObjectiveLabel = 'AnnualObjectiveLabel',
  WeakCorrelationEnabled = 'WeakCorrelationEnabled',
  XMatrixPlayBackEnabled = 'XMatrixPlayBackEnabled',
  TrafficLightsLongTermObjectivesEnabled = 'TrafficLightsLongTermObjectivesEnabled',
  KpiForecastEnabled = 'KpiForecastEnabled',
  AverageSumFunctionsEnabled = 'AverageSumFunctionsEnabled',
  KPITargetCreationLimited = 'KPITargetCreationLimited',
  KPITargetCreationLimitYear = 'KPITargetCreationLimitYear',
  KPITargetCreationLimitMonth = 'KPITargetCreationLimitMonth',
}

export enum ProjectSettingsFieldEnum {
  ProjectProblemTooltip = 'ProjectProblemTooltip',
  ProjectBusinessBenefitsTooltip = 'ProjectBusinessBenefitsTooltip',
  ProjectBusinessGoalsTooltip = 'ProjectBusinessGoalsTooltip',
  ProjectScopeTooltip = 'ProjectScopeTooltip',
  ProjectRiskTooltip = 'ProjectRiskTooltip',
  ProjectResultTooltip = 'ProjectResultTooltip',
  ProjectImplementationObjectivesTooltip = 'ProjectImplementationObjectivesTooltip',
  ProjectDeliverablesTooltip = 'ProjectDeliverablesTooltip',
}
